import { captureException } from '@sentry/node';
import {
  Contribution,
  ContributionGaudi,
  DeletedContributionReason,
} from 'Shared/types/contribution';
import { updateContribution, updateGaudiContribution } from './';

type Response = {
  success: boolean;
  message: string;
  contribution?: Contribution | ContributionGaudi;
};

export const markAgreementDeleted = async ({
  id,
  reason,
  gaudiDB,
  apiToken,
}: {
  id: string;
  reason: DeletedContributionReason;
  gaudiDB?: boolean;
  apiToken: string;
}): Promise<Response> => {
  try {
    let res: Response;
    if (gaudiDB) {
      res = await updateGaudiContribution(
        id,
        {
          deleted: { datetime: new Date(), reason },
        },
        false,
        apiToken
      );
    } else {
      res = await updateContribution(
        id,
        {
          deleted: { date: new Date().toISOString(), reason },
        },
        false,
        apiToken
      );
    }
    return res;
  } catch (error) {
    captureException(`Error on markAgreementDeleted.ts: ${error}`);
  }
};

export const markAgreementDeletedBothDBs = async (
  id: string,
  reason: DeletedContributionReason,
  apiToken: string
): Promise<void> => {
  try {
    await markAgreementDeleted({ id, reason, gaudiDB: false, apiToken });
    await markAgreementDeleted({ id, reason, gaudiDB: true, apiToken });
    return;
  } catch (error) {
    captureException(`Error on markAgreementDeletedBothDBs: ${error}`);
  }
};
