import { gql } from '@apollo/client';
import { User } from 'Shared/types';
import getClient from 'Shared/utils/apollo-client';

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      _id
      email
      name
      role
      createdAt
      updatedAt
    }
  }
`;

export const createUserGql = async (
  data: Partial<User>,
  apiKey: string
): Promise<User> => {
  const client = getClient(apiKey);
  const { data: res } = await client.mutate({
    mutation: CREATE_USER,
    variables: { data },
  });
  return res.createUser;
};
