import React from 'react';
import Map from 'ol/Map';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Pixel } from 'ol/pixel';
import Geometry from 'ol/geom/Geometry';
import { MapPageProps } from 'Client/pages';
import { FEATURE_TYPES } from 'Client/constants/map';
import { MapFeaturePagesList } from 'Server/services/map/getOtherProposals';
import { PageFeatures } from 'Shared/types/page';
import { FilterOptions } from 'Client/pages/map/types';
import { Filters } from 'Client/utils/reduxReducers/filters/FilterState';
import { Features } from 'Shared/types';
import { Contribution, ContributionStatus } from 'Shared/types/contribution';

type MultiselectOption = {
  label: string;
  value: string;
  checked: boolean;
  icon?: React.ReactNode;
};

export interface QueryFilterProps {
  questionId?: string;
  value?: string;
  isMultiple?: boolean;
  queryOrder?: number;
}

export type CustomLayer = {
  id: string;
  title: string;
  description: string;
  proposal: {
    slug: string;
    contributionsNumber: number;
  };
  imageUrl: string;
  slug: string;
  isPlanningApp: boolean;
  proposalId: string;
  infoPointId?: string;
  hoverablePopup?: boolean;
  externalLink?: string;
  isNavigationalMap?: boolean;
};

// To Do: map all available layers
export type CustomLayers = 'Postal district';

declare global {
  interface Window {
    mapp: XyzV4;
  }
}

export type XyzV4 = {
  Mapview: (e) => void;
  dictionaries: Record<string, string>;
  dictionary: Record<string, string>;
  hash: string;
  hooks: GLHooks;
  language: Record<string, string>;
  layer: {
    Style: (workspace: GLLayer) => void;
  };
  location: GLLocations;
  mapview: GLMapViewV4;
  plugins: () => void;
  utils: {
    paramString: (data) => string;
  };
  version: string;
};

export type XyzV4State = {
  xyz: Xyz;
  draftContributionLayer: VectorLayer<VectorSource>;
  infoPanelOpen: boolean;
  activeInfoPanelTab: number;
  welcomePanel: boolean;
  mapEditMode: boolean;
  mode: 'map' | 'satellite' | '3d' | 'street-view';
  userAgreements: Array<Contribution<'agreement'>>;
  selectedContribution: Contribution;
  selectedCustomLayer: CustomLayer;
  activeLayers: Array<CustomLayers>;
  highlightedContribution: Partial<ContributionMapEntry>;
  proposal: Partial<MapPageProps>;
  userId: string;
  highlightedCustomLayer: {
    position: { x: number; y: number };
  } & CustomLayer;

  image3d: {
    url: string;
    hotspots: string;
  };
  uniqueId: string;
  proposals: Array<MapFeaturePagesList>;
  contributionFlow: Partial<{
    lat: number;
    lng: number;
    type: 'comment' | 'agreement';
    data: Partial<Contribution>;
    voiceAnswers?: Record<string, string>;
    contributionId: string;
    geometry: {
      coordinates: number[];
    };
  }>;
  showFilters: boolean;
  isContributionFlowStarted: boolean;
  features: Record<string, Array<any>>;
  featureFlags: PageFeatures;
  filterData: {
    queryFilters: QueryFilterProps[];
    filterValues: FilterOptions['options'][];
  };
  editingFeature: EditingMapFeature;
};

export type XyzState = {
  xyz: Xyz;
  draftContributionLayer: VectorLayer<VectorSource>;
  infoPanelOpen: boolean;
  activeInfoPanelTab: number;
  welcomePanel: boolean;
  mapEditMode: boolean;
  mode: 'map' | 'satellite' | '3d' | 'street-view';
  userAgreements: Array<Contribution<'agreement'>>;
  selectedContribution: Contribution;
  selectedCustomLayer: CustomLayer;
  activeLayers: Array<CustomLayers>;
  highlightedContribution: Partial<ContributionMapEntry>;
  proposal: Partial<MapPageProps>;
  userId: string;
  highlightedCustomLayer: {
    position: { x: number; y: number };
  } & CustomLayer;

  image3d: {
    url: string;
    hotspots: string;
  };
  uniqueId: string;
  proposals: Array<MapFeaturePagesList>;
  contributionFlow: Partial<{
    lat: number;
    lng: number;
    type: 'comment' | 'agreement';
    data: Partial<Contribution>;
    voiceAnswers?: Record<string, string>;
    contributionId: string;
    geometry: {
      coordinates: number[];
    };
  }>;
  showFilters: boolean;
  isContributionFlowStarted: boolean;
  features: Record<string, Array<any>>;
  featureFlags: PageFeatures;
  filterData: {
    queryFilters: QueryFilterProps[];
    filterValues: (FilterOptions['options'] | MultiselectOption[])[];
  };
  editingFeature: EditingMapFeature;
};

export type Xyz = {
  dataviews: GLDataViews;
  defaults: GLDefaults;
  gazetteer: GLGazetteer;
  hooks: GLHooks;
  host: string;
  language: Record<string, string>;
  layers: GLLayers;
  locale: GLLocale;
  locations: GLLocations;
  map: Map;
  mapview: GLMapView;
  plugins: () => void;
  proxy: () => void;
  query: () => void;
  utils: {
    paramString: (data: Record<string, unknown>) => string;
    verticeGeoms: () => void;
    style: (style, feature) => void;
  };
  version: string;
  workspace: {
    get: {
      locale: ({ locale }) => GLLocale;
    };
  };
};

declare global {
  interface Window {
    _xyz: Xyz;
  }
}

export type GLDataViews = {
  plugins: unknown;
  create: () => void;
};

export type GLDefaults = {
  colours: Array<{ hex: string; name: string }>;
};

export type GLGazetteer = {
  createFeature: () => void;
  icon: GLGazetteerIcon;
  init: () => void;
  search: () => void;
  select: () => void;
  sources: GLGazetteerSources;
};

export type GLGazetteerIcon = {
  anchor: Array<number>;
  colorDot: string;
  colorMarker: string;
  scale: number;
  type: string;
};

export type GLGazetteerSources = {
  glx: () => void;
  google: () => void;
  mapbox: () => void;
};

export type GLHooks = {
  current: Record<string, unknown>;
  filter: () => void;
  push: () => void;
  remove: () => void;
  removeAll: () => void;
  set: () => void;
};

export type GLLayers = {
  decorate: () => void;
  list: Record<
    | 'Custom'
    | 'Custom 4258'
    | '3d View'
    | 'Satellite'
    | 'Mapbox Base'
    | 'Contributions'
    | 'Postal district',
    GLLayer
  >;
  listview: {
    init: () => void;
  };
  load: () => void;
  plugins: Record<string, string>;
  view: GLLayersView;
};

export type GLLayer = {
  srid: number;
  infotip: () => void;
  show: () => void;
  remove: () => void;
  hide: () => void;
  reload: () => void;
  source: VectorSource<Geometry>;
  display: boolean;
  table: string;
  filter: {
    current?: string;
  };
  key: string;
  L: VectorLayer<VectorSource>;
};

export type GLLayersView = {
  create: () => void;
  data: { panel: () => void };
  download: { panel: () => void };
  draw: GLLayersViewDraw;
  filter: GLLayersViewFilter;
  report: { panel: () => void };
  style: GLLayersViewStyle;
};

export type GLLayersViewDraw = {
  circle: () => void;
  freehand: () => void;
  isoline_here: () => void;
  isoline_mapbox: () => void;
  line: () => void;
  panel: () => void;
  point: () => void;
  polygon: () => void;
  rectangle: () => void;
};

export type GLLayersViewFilter = {
  block: () => void;
  filter_boolean: () => void;
  filter_date: () => void;
  filter_in: () => void;
  filter_numeric: () => void;
  filter_text: () => void;
  panel: () => void;
  reset: () => void;
};

export type GLLayersViewStyle = {
  legend: () => void;
  panel: () => void;
  themes: {
    basic: () => void;
    bivariate: () => void;
    categorized: () => void;
    dynamic: () => void;
    grid: () => void;
  };
};

export type GLLocale = {
  bounds: {
    north: number;
    easts: number;
    south: number;
    west: number;
  };
  key: string;
  layers: Array<string>;
  maxZoom: number;
  minZoom: number;
  searchZoom?: number;
  scrollWheelZoom: boolean;
  showScaleBar: boolean;
  target: Element;
};

export type GLLocations = {
  decorate: () => void;
  list: Array<{ colorFilter: string; style: Record<string, string> }>;
  listview: { init: () => void };
  plugins: Record<string, string>;
  select: (params: string) => void;
  selectCallback: () => void;
  view: {
    boolean: () => void;
    create: () => void;
    dataview: () => void;
    documents: () => void;
    edit: {
      date: () => void;
      input: () => void;
      options: () => void;
      tange: () => void;
      textarea: () => void;
    };
    geometry: () => void;
    images: () => void;
    infoj: () => void;
    json: () => void;
    report: () => void;
    streetview: () => void;
  };
};

export type GLMapView = {
  attributions: { links: Element; create: () => void; check: () => void };
  changeEndEvent: CustomEvent;
  create: ({
    target,
    scrollWheelZoom,
  }: {
    target: Element;
    scrollWheelZoom: boolean;
  }) => void;
  flyToBounds: (extent: Array<number>, options: object) => void;
  geoJSON: () => void;
  getBounds: () => void;
  getZoom: () => number;
  icon: () => void;
  infotip: {
    create: () => void;
    position: () => void;
  };
  interaction: {
    current: {
      featureSet: Set<unknown>;
      timeout: number;
      begin: () => void;
      finish: () => void;
    };
    draw: {
      Layer: Event;
      begin: (
        params: Record<string, unknown> & {
          drawend?: (event: DrawEvent) => void;
        }
      ) => void;
      cancel: () => void;
      feature: () => void;
      finish: () => void;
      format: {
        writeFeature: (
          feature: Feature,
          config: {
            dataProjection: string;
            featureProjection: string;
          }
        ) => string;
      };
      polygonKinks: () => void;
      update: () => void;
      interaction: Draw;
    };
    edit: {
      begin: (params: string) => void;
      finish: () => void;
      style: Array<Record<string, unknown>>;
      update: () => void;
    };
    highlight: {
      feature: any;
      featureSet: Set<unknown>;
      timeout: number;
      begin: () => void;
      finish: () => void;
    };
    initHighlight: () => void;
    zoom: {
      Layer: Event;
      begin: () => void;
      cancel: () => void;
      finish: () => void;
    };
  };
  layers: GLLayers['list'];
  layer: {
    styleFunction: (layer: GLLayer) => void;
    cluster: () => void;
    clusterLabel: () => void;
    geojson: () => void;
    grid: () => void;
    mbtiles: () => void;
    mvt: () => void;
    mvtLabel: () => void;
    tiles: () => void;
  };
  Map: {
    on: (event: string, cb: (event: { pixel: Pixel }) => void) => EventListener;
  };
  locate: {
    active: boolean;
    icon: { type: string; scale: number };
    marker: Event;
    toggle: () => void;
  };
  node: { style: { cursor: string } };
  pointerLocation: { x: number; y: number };
  popup: { create: () => void; node };
  position: Array<number>;
  srid: string;
};

type GLMapViewV4 = {
  attributions: { links: Element; create: () => void; check: () => void };
  changeEndEvent: CustomEvent;
  create: ({
    target,
    scrollWheelZoom,
  }: {
    target: Element;
    scrollWheelZoom: boolean;
  }) => void;
  fitView: (extent: Array<number>, options: object) => void;
  geoJSON: () => void;
  getBounds: () => void;
  getZoom: () => number;
  icon: () => void;
  infotip: {
    create: () => void;
    position: () => void;
  };
  interactions: {
    current: {
      featureSet: Set<unknown>;
      timeout: number;
      begin: () => void;
      finish: () => void;
    };
    draw: {
      Layer: Event;
      begin: (
        params: Record<string, unknown> & {
          drawend?: (event: DrawEvent) => void;
        }
      ) => void;
      cancel: () => void;
      feature: () => void;
      finish: () => void;
      format: {
        writeFeature: (
          feature: Feature,
          config: {
            dataProjection: string;
            featureProjection: string;
          }
        ) => string;
      };
      polygonKinks: () => void;
      update: () => void;
      interaction: Draw;
    };
    edit: {
      begin: (params: string) => void;
      finish: () => void;
      style: Array<Record<string, unknown>>;
      update: () => void;
    };
    highlight: {
      feature: Record<string, string>;
      featureSet: Set<unknown>;
      timeout: number;
      begin: () => void;
      finish: () => void;
    };
    zoom: {
      Layer: Event;
      begin: () => void;
      cancel: () => void;
      finish: () => void;
    };
  };
  layers: GLLayers['list'];
  layer: {
    styleFunction: (layer: GLLayer) => void;
    cluster: () => void;
    clusterLabel: () => void;
    geojson: () => void;
    grid: () => void;
    mbtiles: () => void;
    mvt: () => void;
    mvtLabel: () => void;
    tiles: () => void;
  };
  Map: Map;
  locale: string;
  locate: {
    active: boolean;
    icon: { type: string; scale: number };
    marker: Event;
    toggle: () => void;
  };
  node: { style: { cursor: string } };
  pointerLocation: { x: number; y: number };
  popup: { create: () => void; node };
  position: Array<number>;
  srid: string;
};

export enum MapProjection {
  /**
   * The Web Mercator/Spherical Mercator projection used in web maps.
   *
   * Used in `custom_layers`.
   *
   * @see https://epsg.io/3857
   */
  WEB_MERCATOR = 'EPSG:3857',

  /**
   * The World Geodetic coordinates as used in GPS.
   * This is longitude and latitude expressed in ranges of -180/180 and -90/90.
   *
   * Used when saving coordinates.
   *
   * @see https://epsg.io/4326
   */
  WORLD_GEODETIC_GPS = 'EPSG:4326',

  /**
   * ETRS89 (European Terrestrial Reference System 1989).
   *
   * Coordinate system used for location data within Europe.
   *
   * Used in `custom_layer_4258`.
   *
   * @see https://epsg.io/4258
   */
  ETRS = 'EPSG:4258',
}

export enum MapLayerNames {
  CONTRIBUTIONS = 'Contributions',
  '3D_VIEW' = '3d View',
  INFO_POINTS = 'Custom',
  BOUNDARIES = 'Custom 4258', // Non Hoverable Feature
  POSTAL_DISTRICT = 'Postal district',
}
export interface MapBoxResponseFeatures {
  place_formatted: string;
  mapbox_id: string;
  full_address: string;
  distance: number;
  name: string;
  feature_type: string;
  language: string;
  maki: string;
}

export interface MapBoxApiResponse {
  url: string;
  attribution: Array<string>;
  suggestions?: Array<MapBoxResponseFeatures>;
}

export interface MapBoxApiAdressResponse extends MapBoxApiResponse {
  features: Array<MapBoxFeature>;
}

export interface MapBoxFeature {
  geometry: {
    type: string;
    coordinates: Array<number>;
  };
  place_name: string;
  id: string;
  text: string;
  place_formatted: string;
  mapbox_id: string;
  full_address: string;
  distance: number;
  name: string;
  feature_type: string;
  language: string;
  maki: string;
  relevance?: number;
}

export interface MapBoxSearchBoxRetrieveApiFeatures {
  type: string;
  geometry: { coordinates: number[]; type: string };
  properties: {
    name: string;
    mapbox_id: string;
    feature_type: string;
    address: string;
    full_address: string;
    place_formatted: string;
    context: {
      country: {
        name: string;
        country_code: string;
        country_code_alpha_3: string;
      };
      postcode: { name: string };
      place: { name: string };
      locality: { name: string };
      neighborhood: { name: string };
      address: {
        name: string;
        address_number: string;
        street_name: string;
      };
      street: { name: string };
    };
    coordinates: {
      latitude: number;
      longitude: number;
      routable_points: [
        {
          name: string;
          latitude: number;
          longitude: number;
        },
      ];
    };
    maki: string;
    poi_category: string[];
    poi_category_ids: string[];
    external_ids: { foursquare: string };
  };
}

export interface MapBoxSearchBoxRetrieveApiResponse {
  type: string;
  query: Array<string>;
  suggestions: Array<MapBoxResponseFeatures>;
  features: MapBoxSearchBoxRetrieveApiFeatures[];
  attribution: string;
  url: string;
}

export type ContributionMapEntry = {
  cat?: string;
  contribution_id: string;
  coords: Array<number>;
  count: number;
  draft?: boolean;
  geom: string;
  geometry: Record<string, unknown>;
  id: string;
  is_deleted: boolean;
  label?: string;
  metadata?: Record<string, unknown>; // AnswersPopulated at the moment
  page_id: string;
  pivot?: string;
  position: { x: number; y: number };
  project: string;
  sentiment?: number;
  size?: number;
  slug: string;
  status?: string;
  user_id?: string;
  1;
};

export type CustomLayerMapEntry = {
  id: number;
  geometry: {
    type: string;
    crs: { type: string; properties: { name: MapProjection } };
    coordinates: number[];
  };
  properties: {
    metadata?: Record<string, unknown>;
  };
  type: FEATURE_TYPES;
};

export interface MapContextState {
  state: XyzState;
  dispatch: React.Dispatch<MapAction>;
  version?: string;
}

export interface XyzCombined {
  dataviews?: string;
  defaults?: string;
  gazetteer?: string;
  hooks?: string;
  host?: string;
  language?: Record<string, string>;
  layers?: GLLayers;
  locale?: string;
  locations?: string;
  map: Map;
  mapview: GLMapView;
  plugins?: () => void;
  proxy?: () => void;
  query?: () => void;
  utils?: {
    paramString: (data: Record<string, unknown>) => string;
  };
  version: string;
  workspace?: {
    get: {
      locale: ({ locale }) => GLLocale;
    };
  };
}

export type MapAction =
  | { type: 'INITIALIZE'; payload: Xyz }
  | { type: 'TOGGLE_INFO_PANEL' }
  | {
      type: 'OPEN_INFO_PANEL';
      payload: Partial<Pick<XyzState, 'infoPanelOpen' | 'activeInfoPanelTab'>>;
    }
  | { type: 'CLOSE_WELCOME_PANEL' }
  | { type: 'SELECT_CONTRIBUTION'; payload: Contribution }
  | { type: 'SELECT_CUSTOM_LAYER'; payload: CustomLayer }
  | { type: 'ADD_COMMENT'; payload: XyzState['contributionFlow'] }
  | { type: 'ADD_AGREEMENT'; payload: Partial<XyzState['contributionFlow']> }
  | { type: 'CLEAR_HIGHLIGHT' }
  | { type: 'SET_COMMENT_DATA'; payload: XyzState['contributionFlow']['data'] }
  | {
      type: 'SET_VOICE_DATA';
      payload: XyzState['contributionFlow']['voiceAnswers'];
    }
  | { type: 'CLEAR_LEFT_PANEL' }
  | { type: 'CLEAR_CONTRIBUTION_FLOW' }
  | { type: 'CLEAR_ANSWERS' }
  | { type: 'CLEAR_VOICE_ANSWERS' }
  | { type: 'SET_3D_IMAGE'; payload: XyzState['image3d'] }
  | {
      type: 'TOGGLE_LAYER';
      payload: {
        layer: keyof GLLayers['list'];
        newPage: Partial<MapPageProps>;
      };
    }
  | { type: 'SET_PROPOSAL'; payload: XyzState['proposal'] }
  | { type: 'CHANGE_MODE'; payload: XyzState['mode'] }
  | { type: 'TOGGLE_MAP_EDIT_MODE'; payload: Pick<XyzState, 'mapEditMode'> }
  | {
      type: 'SET_HIGHLIGHTED_FEATURE';
      payload: {
        feature: ContributionMapEntry;
        position: { x: number; y: number };
      };
    }
  | {
      type: 'SET_CUSTOM_LAYER_HIGHLIGHT';
      payload: XyzState['highlightedCustomLayer'];
    }
  | { type: 'SET_PROPOSALS'; payload: Pick<XyzState, 'proposals'> }
  | { type: 'CLEAR_CUSTOM_LAYER_HIGHLIGHT' }
  | { type: 'SET_USER_AGREEMENTS'; payload: Pick<XyzState, 'userAgreements'> }
  | { type: 'SET_ACTIVE_LAYERS'; payload: XyzState['activeLayers'] }
  | { type: 'SET_CONTRIBUTION_FLOW_STARTED'; payload: boolean }
  | { type: 'RELOAD_LAYER'; payload: { layer: keyof GLLayers['list'] } }
  | {
      type: 'FOCUS_CONTRIBUTION';
      payload: { cid: string; dispatch: React.Dispatch<MapAction> };
    }
  | { type: 'SET_FEATURES'; payload: Pick<XyzState, 'features'> }
  | { type: 'FILTER_3D_BY_PAGE_ID'; payload: { pageId: string } }
  | {
      type: 'SET_MAP_FILTERS';
      payload: {
        filters: ContributionFilter[] | Filters[];
        table: keyof GLLayers['list'];
      };
    }
  | {
      type: 'SET_CONTRIBUTION_FILTERS_DATA';
      payload: Pick<XyzState, 'filterData'>;
    }
  | {
      type: 'INITIALIZE_LAYERS';
      payload: {
        xyz?: Xyz;
        dispatch: React.Dispatch<MapAction>;
        project: { _id: string; features?: Features };
        page: Partial<MapPageProps>;
        lang?: string;
        userId?: string;
        uniqueId: string;
      };
    }
  | {
      type: 'FILTER_COMMENTS';
      payload: {
        positiveChecked;
        mostlyPositiveChecked;
        neutralChecked;
        mostlyNegativeChecked;
        negativeChecked;
        uniqueId;
        project;
        pivot?;
        isContributionId?;
        metadataFilters?;
      };
    }
  | {
      type: 'SET_EDITING_FEATURE';
      payload: {
        editingFeature: EditingMapFeature;
      };
    }
  | { type: 'INVALID' };

type FilterValue =
  | {
      eq: string;
    }
  | {
      boolean: boolean;
    }
  | {
      in: string[];
    }
  | {
      in: number[];
    }
  | { null: boolean }
  | { like: string };

type ContributionPostgres = {
  page_id: string;
  project: string;
  is_deleted: boolean;
  draft: boolean;
  status: ContributionStatus;
  user_id: string;
  sentiment: number;
  pivot: string;
};

export type ContributionFilter = {
  [key in keyof ContributionPostgres]?: FilterValue;
};

export enum DrawStateValues {
  DRAWING_STARTED = 'drawingStarted',
  HAS_LESS_THAN_THREE_POINTS = 'hasLessThanThreePoints',
  HAS_MORE_THAN_TWO_POINTS = 'hasMoreThanTwoPoints',
  DRAWING_FINISHED = 'drawingFinished',
}

export interface EditingMapFeature {
  type: string;
  geometry: {
    type: DrawShapeTypes;
    crs: {
      type: string;
      properties: {
        name: string;
      };
    };
    coordinates: number[][][];
  };
  id: number;
  properties: {
    metadata: {
      active: boolean;
      name?: string;
      title?: string;
      imageUrl?: string;
      imageAlt?: string;
      svg?: string;
      proposalId?: string;
      description?: string;
      isPlanningApp?: boolean;
      externalLink?: string;
      zIndex?: number;
      scriptDetails?: {
        author: string;
        metadata: {
          newLink: string;
          oldLink: string;
        };
        timestamp: string;
        scriptName: string;
        scriptDescription: string;
      }[];
      hoverablePopup?: boolean;
      fillColor?: string;
      strokeColor?: string;
      highlightFillColor?: string;
      highlightStrokeColor?: string;
      lineStroke?: number;
    };
  };
  table?: string;
  layer?: string;
}

export enum DrawShapeTypes {
  POINT = 'Point',
  POLYGON = 'MultiPolygon',
  CIRCLE = 'Polygon',
  LINE = 'LineString',
  MULTI_LINE = 'MultiLineString',
  SHAPEFILE = 'Shapefile',
}
