import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { readableColor } from 'polished';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useApolloClient } from '@apollo/client';
import { updateFeature as updateFeatureV4 } from 'Client/services/geolytix/v4';
import { DrawShapeTypes, GLLayers } from 'Shared/types/map';
import {
  BoldInfoIcon,
  CloseIcon,
  ColorBucketIcon,
  EditModeTrashIcon,
} from 'Atoms/Icons';
import {
  EditSectionTitle,
  RoundedDropdown,
} from 'Client/pages/edit/components/Form';
import {
  ColorPicker,
  CustomLayerPreview,
  ToolTip,
} from 'Client/components/molecules';
import { useMap } from 'Client/utils/hooks';
import { ImageUpload } from 'Client/components/molecules/ImageUpload/ImageUpload';
import { validateMapFields } from 'Client/utils/reduxReducers/editMode/validationReducer';
import { EDIT_MODE_VALIDATION_ACTIONS } from 'Client/pages/edit/reducers/editModeValidationsReducer/constants';
import { FormValidationStatus } from 'Client/types';
import { RootState } from 'Client/redux-store';
import { CustomerProjectPageSelector } from 'Client/components/organisms/CustomerProjectPageSelector/customerProjectPageSelector';
import { REPROJECT_CUSTOM_LAYER_4258 } from 'Client/services/map/reprojectCustomLayer4258.gql';
import { GET_CUSTOMER_AND_PROJECT_BY_PAGE_ID } from 'Client/services/page/getCustomerAndProjectInfo.gql';
import {
  InputWithButton,
  PickColorButton,
  MapEditButton,
  MapEditButtonInverted,
  Section,
  SectionField,
  SectionInput,
  StyledTextAreaInput,
  InputTitle,
  RowSectionField,
  StyledSwitch,
  DeleteCancelButton,
  TooltipMessage,
} from '../../MapEditorV2.styles';
import {
  ButtonsSection,
  CancelButton,
  CustomizationTabOption,
  CustomizationTypeSelector,
  DeleteFeatureContent,
  DeleteFeatureModal,
  Details,
  DoneButton,
  FeatureButtons,
  FeatureEditorContent,
  FeatureEditorHeader,
  FeatureInfo,
  HoverPanelModal,
  PreviewContainer,
  PreviewContent,
  TrashButton,
  Wrapper,
} from './index.styles';
import { FeatureIconContainer } from '../Layers/index.styles';
import {
  ContentLeftSide,
  ContentRightSide,
  ModalContent,
  PreviewItemSection,
} from '../MapInfo/index.styles';
import { FeatureIcon } from '../shared/FeatureIcon';

type LinkTypes = 'EXTERNAL' | 'CP_TILE';

interface FeatureEditorProps {
  handleSave: () => void;
  reloadFeatures: () => void;
  allFeatureNames: string[];
}
export const FeatureEditor = ({
  handleSave,
  reloadFeatures,
  allFeatureNames,
}: FeatureEditorProps) => {
  const {
    dispatch,
    state: { editingFeature, xyz },
  } = useMap();
  const router = useRouter();
  const { t } = useTranslation('customer');
  const { errors } = useSelector((s: RootState) => s.editModeValidation);
  const client = useApolloClient();

  const dispatchRdx = useDispatch();
  const featureProps = editingFeature?.['properties']?.metadata;
  const allowCustomization = editingFeature.geometry.type === 'Point';

  const [hoverPanelModalOpen, setHoverPanelModalOpen] = React.useState(false);
  const [linkType, setLinkType] = React.useState<LinkTypes>(
    featureProps.proposalId ? 'CP_TILE' : 'EXTERNAL'
  );
  const [featureData, setFeatureData] = React.useState(featureProps);
  const [deleteShapeModalOpen, setDeleteShapeModalOpen] = React.useState(false);
  const [hoverPanelCustomization, setHoverPanelCustomization] =
    React.useState('STANDARD');
  const [validated, setValidated] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [isFirstRender, setIsFirstRender] = React.useState(true);
  const [selectedPage, setSelectedPage] = React.useState({
    label: featureData.title,
    value: featureData.proposalId,
    _id: featureData.proposalId,
  });

  const type = {
    [DrawShapeTypes.LINE]: t('Line'),
    [DrawShapeTypes.MULTI_LINE]: t('Multi Line'),
    [DrawShapeTypes.POLYGON]: t('Polygon'),
    [DrawShapeTypes.CIRCLE]: t('Circle'),
    [DrawShapeTypes.POINT]: t('Point'),
  }[editingFeature?.geometry?.type] as string;

  const linkTypes: { value: LinkTypes; label: string }[] = [
    { value: 'EXTERNAL', label: 'External link' },
    { value: 'CP_TILE', label: 'Commonplace tile' },
  ];

  React.useEffect(() => {
    const featureProps = editingFeature?.['properties']?.metadata;
    setFeatureData(featureProps);
    if (!allowCustomization) setHoverPanelCustomization('STANDARD');
  }, [editingFeature.id]);

  React.useEffect(() => {
    if (!selectedPage) return;
    if (String(featureData.proposalId) === String(selectedPage._id)) return;
    (async () => {
      if (!selectedPage._id) return;
      const res = await fetch(
        `/api/proposals/byId?proposalId=${selectedPage._id}&lang=${router.locale}`
      ).then((res) => res.json());
      const { proposal } = res;
      const { image, title, description } = proposal;
      setFeatureData({
        ...featureData,
        imageUrl: image.src,
        title,
        description,
        proposalId: selectedPage._id,
      });
    })();
  }, [selectedPage]);
  React.useEffect(() => {
    setValidated(false);
    dispatch({
      type: 'SET_EDITING_FEATURE',
      payload: {
        editingFeature: {
          ...editingFeature,
          properties: {
            ...editingFeature.properties,
            metadata: featureData,
          },
        },
      },
    });
  }, [featureData]);

  const handleFeatureChange = (key, value) => {
    const _featureData = {
      ...featureData,
      [key]: value,
    };
    setFeatureData(_featureData);
  };

  const validateFeatureFields = () => {
    dispatchRdx(
      validateMapFields({
        state: {},
        action: {
          type: EDIT_MODE_VALIDATION_ACTIONS.VALIDATE_MAP_FIELDS,
          payload: {
            fields: [
              {
                name: `map-edit/layers/shapes/${editingFeature.id}/name`,
                value: featureData.name,
                requirements: [
                  {
                    operation: '$nin',
                    value: [...allFeatureNames, null, undefined, ''],
                    message: t(
                      'You cannot save shapes without a name, name must be unique and different to other shapes'
                    ),
                    type: 'error',
                  },
                ],
              },
            ],
          },
        },
      })
    );
  };

  const validateHoverPanelFields = () => {
    dispatchRdx(
      validateMapFields({
        state: {},
        action: {
          type: EDIT_MODE_VALIDATION_ACTIONS.VALIDATE_MAP_FIELDS,
          payload: {
            fields: [
              {
                name: `map-edit/layers/shapes/${editingFeature.id}/hover-panel/title`,
                value: '',
                requirements: [
                  {
                    operation: '$and',
                    value: [
                      {
                        operation: '$eq',

                        fieldValue: featureData.hoverablePopup,
                        value: true,
                      },
                      {
                        operation: '$eq',

                        fieldValue: linkType,
                        value: 'EXTERNAL',
                      },
                      {
                        operation: '$nin',
                        fieldValue: featureData.title,
                        value: [null, undefined, ''],
                      },
                    ],
                    message: t('You cannot save a hover panel without a title'),
                    type: 'error',
                  },
                ],
              },
              {
                name: `map-edit/layers/shapes/${editingFeature.id}/hover-panel/link-url`,
                value: '',
                requirements: [
                  {
                    operation: '$and',
                    value: [
                      {
                        operation: '$eq',
                        fieldValue: featureData.hoverablePopup,
                        value: true,
                      },
                      {
                        operation: '$eq',
                        fieldValue: linkType,
                        value: 'EXTERNAL',
                      },
                      {
                        operation: '$in',
                        fieldValue: featureData.externalLink,
                        value: [null, undefined, ''],
                      },
                      {
                        operation: '$notMatch',
                        value: /^(https?:\/\/)/,
                        fieldValue: featureData.externalLink,
                      },
                    ],
                    message: t(
                      'Please add a properly formatted URL including https://'
                    ),
                    type: 'error',
                  },
                ],
              },
            ],
          },
        },
      })
    );
  };

  React.useEffect(() => {
    setValidated(false);
  }, [featureData]);

  const validateFields = () => {
    validateFeatureFields();
    setValidated(true);
  };

  const _handleSave = () => {
    if (editingFeature.id && String(editingFeature.id) !== 'new') {
      updateFeature();
      return dispatch({
        type: 'SET_EDITING_FEATURE',
        payload: { editingFeature: null },
      });
    }
    handleSave();
  };
  const updateFeature = async () => {
    try {
      await updateFeatureV4({
        layer: editingFeature.layer,
        xyz,
        table: editingFeature.table,
        id: editingFeature.id,
        body: { metadata: featureData },
      });

      if (
        editingFeature.table === 'custom_layer_4258' &&
        featureData.hoverablePopup
      ) {
        const {
          data: { reprojectCustomLayer4258 },
        } = await client.mutate({
          mutation: REPROJECT_CUSTOM_LAYER_4258,
          variables: {
            reprojectCustomLayer4258Id: editingFeature.id,
          },
        });
        const formattedReturn = {
          ...reprojectCustomLayer4258,
          ...JSON.parse(reprojectCustomLayer4258.geometry),
          properties: {
            ...reprojectCustomLayer4258.metadata,
          },
          table: 'custom_layers',
          layer: 'Custom',
        };
        delete formattedReturn.metadata;
        delete formattedReturn.geom_3857;
        delete formattedReturn.user_id;
        delete formattedReturn.slug;
        delete formattedReturn.geometry;
      }
      dispatch({
        type: 'RELOAD_LAYER',
        payload: { layer: editingFeature.layer as keyof GLLayers['list'] },
      });
      reloadFeatures();
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
  }, [linkType, featureData.hoverablePopup]);

  React.useEffect(() => {
    if (linkType === 'CP_TILE') {
      delete featureData.externalLink;
      (async () => {
        if (featureData.proposalId && !selectedPage) {
          const {
            data: { getCustomerAndProjectByPageId },
          } = await client.query({
            query: GET_CUSTOMER_AND_PROJECT_BY_PAGE_ID,
            variables: {
              pageId: selectedPage?.value || featureData.proposalId,
            },
          });
          const { customerData, projectData, pageData } =
            getCustomerAndProjectByPageId;

          setSelectedCustomer({
            value: customerData._id,
            label: customerData.name,
          });
          setSelectedProject({
            value: projectData._id,
            label: projectData.name,
          });
          setSelectedPage({
            value: pageData._id,
            label: pageData.name,
            _id: pageData._id,
          });
        }
      })();
    }
    if (linkType === 'EXTERNAL') {
      delete featureData.proposalId;
    }
    setFeatureData(featureData);
  }, [linkType, selectedPage, featureData.proposalId]);

  const deleteShape = async () => {
    await updateFeatureV4({
      layer: editingFeature.layer,
      xyz,
      table: editingFeature.table,
      id: editingFeature.id,
      body: { is_deleted: true },
    });
    dispatch({
      type: 'RELOAD_LAYER',
      payload: { layer: editingFeature.layer as keyof GLLayers['list'] },
    });
    reloadFeatures();
    dispatch({
      type: 'SET_EDITING_FEATURE',
      payload: { editingFeature: null },
    });
  };

  const popupFields = {
    [DrawShapeTypes.POINT]: ['fillColor'],
    [DrawShapeTypes.POLYGON]: [
      'fillColor',
      'strokeColor',
      'highlightColor',
      'highlightStrokeColor',
      'thickness',
    ],
    [DrawShapeTypes.LINE]: ['strokeColor', 'thickness'],
    [DrawShapeTypes.MULTI_LINE]: ['strokeColor', 'thickness'],
    [DrawShapeTypes.CIRCLE]: [
      'fillColor',
      'strokeColor',
      'highlightColor',
      'highlightStrokeColor',
      'thickness',
    ],
  };

  const colorPickerProps = {
    style: { height: '46px' },
    customPosition: {
      left: 'unset',
      bottom: '50px',
      right: '0',
    },
  };
  const getErrorForField = (
    fieldName: string
  ): {
    message: string;
    type: FormValidationStatus['type'];
  } => {
    return errors.find((error) => error.field === fieldName);
  };

  const handleClose = () => {
    if (String(editingFeature.id) === 'new') {
      dispatchRdx(
        validateMapFields({
          state: {},
          action: {
            type: EDIT_MODE_VALIDATION_ACTIONS.SET_ERRORS,
            payload: {
              errors: errors.filter(
                (e) => !e.field.includes(`map-edit/layers/shapes/new`)
              ),
            },
          },
        })
      );
    }
    dispatch({
      type: 'SET_EDITING_FEATURE',
      payload: { editingFeature: null },
    });
  };

  const handlePopupChange = async () => {
    handleFeatureChange('hoverablePopup', !featureData.hoverablePopup);
  };

  return (
    <>
      <Wrapper>
        <FeatureEditorHeader>
          <h4>{t('Shape settings')}</h4>
          <CloseIcon width={18} height={18} onClick={handleClose} />
        </FeatureEditorHeader>
        <FeatureEditorContent>
          <Details>
            <FeatureInfo>
              <FeatureIconContainer
                color={
                  editingFeature.geometry.type === DrawShapeTypes.LINE
                    ? featureData.strokeColor
                    : featureData.fillColor
                }
              >
                <FeatureIcon
                  type={editingFeature.geometry.type as DrawShapeTypes}
                  props={{
                    width: 15,
                    height: 15,
                    color: readableColor(
                      editingFeature.geometry.type === DrawShapeTypes.LINE
                        ? featureData.strokeColor
                        : featureData.fillColor
                    ),
                  }}
                />
              </FeatureIconContainer>
              <p>{featureData.name}</p>
            </FeatureInfo>
            <FeatureButtons>
              {String(editingFeature.id) !== 'new' && (
                <TrashButton
                  onClick={() => {
                    setDeleteShapeModalOpen(true);
                  }}
                >
                  <EditModeTrashIcon color={'black'} width={12} height={15} />
                </TrashButton>
              )}
              {validated &&
              !errors.filter((e) =>
                e.field.includes(`map-edit/layers/shapes/${editingFeature.id}`)
              )?.length ? (
                <DoneButton
                  onClick={_handleSave}
                  statusType={
                    errors.find((e) =>
                      e.field.includes(
                        `map-edit/layers/shapes/${editingFeature.id}`
                      )
                    )?.type
                  }
                >
                  {t('Save')}
                </DoneButton>
              ) : (
                <DoneButton
                  onClick={validateFields}
                  statusType={
                    errors.find((e) =>
                      e.field.includes(
                        `map-edit/layers/shapes/${editingFeature.id}`
                      )
                    )?.type
                  }
                >
                  {t('Apply changes')}
                </DoneButton>
              )}
            </FeatureButtons>
          </Details>
          <MapEditButtonInverted
            onClick={() => setHoverPanelModalOpen(true)}
            statusType={
              errors.find((e) =>
                e.field.includes(
                  `map-edit/layers/shapes/${editingFeature.id}/hover-panel/`
                )
              )?.type
            }
          >
            <h4>{t('Edit hover panel')}</h4>
            <ToolTip
              hoverableElement={<BoldInfoIcon />}
              startPositionHorizontalMutation={-130}
            >
              <TooltipMessage>
                {t('Show / hide and edit the hover panel for your shape.')}
              </TooltipMessage>
            </ToolTip>
          </MapEditButtonInverted>
          <Section>
            <SectionField>
              <EditSectionTitle
                htmlFor={'feature-name-field'}
                label={t('{{type}} name', { type })}
              />
              <SectionInput
                id={'feature-name-field'}
                name={'feature-name-field'}
                type="text"
                placeholder={t('{{type}} 01', { type })}
                value={featureData.name || ''}
                onChange={(e) => {
                  handleFeatureChange('name', e.target.value);
                }}
                status={getErrorForField(
                  `map-edit/layers/shapes/${editingFeature.id}/name`
                )}
              />
            </SectionField>
          </Section>
          <Section>
            {allowCustomization && (
              <SectionField>
                <EditSectionTitle
                  htmlFor={'customization-field'}
                  label={
                    <InputTitle>
                      {t('Customization')}
                      <ToolTip
                        hoverableElement={<BoldInfoIcon />}
                        startPositionHorizontalMutation={-50}
                      >
                        <TooltipMessage>
                          {t(
                            'Choose ‘standard’ to edit the colours or ‘custom’ to upload your own icon / image, in SVG format.'
                          )}
                        </TooltipMessage>
                      </ToolTip>
                    </InputTitle>
                  }
                />
                <CustomizationTypeSelector>
                  <CustomizationTabOption
                    selected={hoverPanelCustomization === 'STANDARD'}
                    onClick={() => setHoverPanelCustomization('STANDARD')}
                  >
                    {t('Standard')}
                  </CustomizationTabOption>
                  <CustomizationTabOption
                    selected={hoverPanelCustomization === 'CUSTOM'}
                    onClick={() => setHoverPanelCustomization('CUSTOM')}
                  >
                    {t('Custom')}
                  </CustomizationTabOption>
                </CustomizationTypeSelector>
              </SectionField>
            )}
          </Section>
          {hoverPanelCustomization === 'STANDARD' ? (
            <Section>
              {popupFields[editingFeature.geometry.type]?.includes(
                'fillColor'
              ) && (
                <SectionField>
                  <EditSectionTitle
                    htmlFor={'feature-colour-field'}
                    label={t('{{type}} colour', { type })}
                  />
                  <InputWithButton buttonSize="20%">
                    <SectionInput
                      id={'feature-colour-field'}
                      name={'feature-colour-field'}
                      type="text"
                      disabled
                      placeholder={t('Type here')}
                      value={featureData.fillColor}
                      onChange={() => {}}
                    />
                    <ColorPicker
                      allowCustom={true}
                      color={featureData.fillColor}
                      handleColorChange={(color) =>
                        handleFeatureChange('fillColor', color)
                      }
                      {...colorPickerProps}
                    >
                      <PickColorButton color={featureData.fillColor}>
                        <ColorBucketIcon
                          color={readableColor(featureData.fillColor)}
                        />
                      </PickColorButton>
                    </ColorPicker>
                  </InputWithButton>
                </SectionField>
              )}
              {popupFields[editingFeature.geometry.type]?.includes(
                'strokeColor'
              ) && (
                <SectionField>
                  <EditSectionTitle
                    htmlFor={'feature-stroke-colour-field'}
                    label={t('Line colour')}
                  />
                  <InputWithButton buttonSize="20%">
                    <SectionInput
                      id={'feature-stroke-colour-field'}
                      name={'feature-stroke-colour-field'}
                      type="text"
                      disabled
                      placeholder={t('Type here')}
                      value={featureData.strokeColor}
                      onChange={() => {}}
                    />
                    <ColorPicker
                      allowCustom={true}
                      color={featureData.strokeColor}
                      handleColorChange={(color) =>
                        handleFeatureChange('strokeColor', color)
                      }
                      {...colorPickerProps}
                    >
                      <PickColorButton color={featureData.strokeColor}>
                        <ColorBucketIcon
                          color={readableColor(featureData.strokeColor)}
                        />
                      </PickColorButton>
                    </ColorPicker>
                  </InputWithButton>
                </SectionField>
              )}
              {popupFields[editingFeature.geometry.type]?.includes(
                'highlightColor'
              ) && (
                <SectionField>
                  <EditSectionTitle
                    htmlFor={'feature-highlight-colour-field'}
                    label={t('Hover fill colour')}
                  />
                  <InputWithButton buttonSize="20%">
                    <SectionInput
                      id={'feature-highlight-colour-field'}
                      name={'feature-highlight-colour-field'}
                      type="text"
                      disabled
                      placeholder={t('Type here')}
                      value={featureData.highlightFillColor}
                      onChange={() => {}}
                    />
                    <ColorPicker
                      allowCustom={true}
                      color={featureData.highlightFillColor}
                      handleColorChange={(color) =>
                        handleFeatureChange('highlightFillColor', color)
                      }
                      {...colorPickerProps}
                    >
                      <PickColorButton color={featureData.highlightFillColor}>
                        <ColorBucketIcon
                          color={readableColor(featureData.highlightFillColor)}
                        />
                      </PickColorButton>
                    </ColorPicker>
                  </InputWithButton>
                </SectionField>
              )}
              {popupFields[editingFeature.geometry.type]?.includes(
                'highlightStrokeColor'
              ) && (
                <SectionField>
                  <EditSectionTitle
                    htmlFor={'feature-highlight-stroke-colour-field'}
                    label={t('Hover Line colour')}
                  />
                  <InputWithButton buttonSize="20%">
                    <SectionInput
                      id={'feature-highlight-stroke-colour-field'}
                      name={'feature-highlight-stroke-colour-field'}
                      type="text"
                      disabled
                      placeholder={t('Type here')}
                      value={featureData.highlightStrokeColor}
                      onChange={() => {}}
                    />
                    <ColorPicker
                      allowCustom={true}
                      color={featureData.highlightStrokeColor}
                      handleColorChange={(color) =>
                        handleFeatureChange('highlightStrokeColor', color)
                      }
                      {...colorPickerProps}
                    >
                      <PickColorButton color={featureData.highlightStrokeColor}>
                        <ColorBucketIcon
                          color={readableColor(
                            featureData.highlightStrokeColor
                          )}
                        />
                      </PickColorButton>
                    </ColorPicker>
                  </InputWithButton>
                </SectionField>
              )}
              {/* {popupFields[editingFeature.geometry.type].includes( // tbi
              'thickness'
            ) && (
              <Section>
                <IncreaseDecreaseField>
                  <p>{t('Thickness')}</p>
                  <IncreaseDecrease
                    amount={featureData.strokeWidth || 1}
                    min={0}
                    setAmount={(amount) => {
                      console.log(amount);
                    }}
                  />
                </IncreaseDecreaseField>
              </Section>
            )} */}
            </Section>
          ) : (
            <Section>
              <SectionField>
                {/* <EditSectionTitle
                htmlFor={'feature-icon-field'}
                label={t('{{cpName}} icons', { cpBrandName })}
              />
               <div>dropdown</div> tbi */}
                <EditSectionTitle
                  htmlFor={'feature-svg-field'}
                  label={t('Upload SVG icon')}
                />
                <ImageUpload
                  respondentSide={false}
                  setImage={(url) => handleFeatureChange('svg', url)}
                  image={featureData.svg || null}
                  showTitle={false}
                  allowedTypes=".svg"
                />
              </SectionField>
            </Section>
          )}
        </FeatureEditorContent>
        <HoverPanelModal
          title={t('Editing hover panel')}
          open={hoverPanelModalOpen}
          onClose={() => setHoverPanelModalOpen(false)}
          data-testid="ConfirmationModal"
        >
          <ModalContent>
            <ContentLeftSide>
              <Section>
                <RowSectionField>
                  <EditSectionTitle
                    htmlFor="show-hover-panel-switch"
                    label={t('Show hover panel')}
                  />
                  <StyledSwitch
                    colorMapping="editModePurple"
                    checked={featureData.hoverablePopup}
                    onChange={() => handlePopupChange()}
                  />
                </RowSectionField>
                <SectionField>
                  <EditSectionTitle
                    htmlFor="link-type-dropdown"
                    label={t('Link type')}
                  />
                  <RoundedDropdown
                    disabled={false}
                    id="link-type-dropdown"
                    data-testid="link-type-dropdown"
                    name="link-type-dropdown"
                    options={linkTypes}
                    isClearable={false}
                    value={linkTypes.find((opt) => opt.value == linkType)}
                    width={'100%'}
                    placeholder={t('Select a type')}
                    handleChange={({ value }) => {
                      setLinkType(value);
                    }}
                  />
                </SectionField>

                {linkType == 'CP_TILE' && (
                  <>
                    <CustomerProjectPageSelector
                      pageTypes={['proposals', 'map']}
                      selectedCustomer={selectedCustomer}
                      setSelectedCustomer={(customer) => {
                        if (
                          selectedCustomer?.value &&
                          customer !== selectedCustomer.value
                        ) {
                          setFeatureData({
                            ...featureData,
                            proposalId: null,
                            title: null,
                            imageUrl: null,
                            description: null,
                          });
                        }
                        setSelectedCustomer(customer);
                      }}
                      selectedProject={selectedProject}
                      setSelectedProject={(project) => {
                        setSelectedProject(project);
                      }}
                      selectedPage={selectedPage}
                      setSelectedPage={(page) => {
                        setSelectedPage(page);
                      }}
                    />
                  </>
                )}
                {linkType == 'EXTERNAL' && (
                  <>
                    <SectionField>
                      <EditSectionTitle
                        htmlFor={'panel-title-field'}
                        label={t('Title')}
                      />
                      <SectionInput
                        id={'panel-title-field'}
                        name={'panel-title-field'}
                        type="text"
                        placeholder={t('Panel title')}
                        value={featureData.title}
                        onChange={(e) => {
                          handleFeatureChange('title', e.target.value);
                        }}
                        status={getErrorForField(
                          `map-edit/layers/shapes/${editingFeature.id}/hover-panel/title`
                        )}
                      />
                    </SectionField>

                    <SectionField>
                      <EditSectionTitle
                        htmlFor={'map-image-field'}
                        label={t('Upload image')}
                      />
                      <ImageUpload
                        respondentSide={false}
                        setImage={(url) => handleFeatureChange('imageUrl', url)}
                        image={featureData.imageUrl || null}
                        showTitle={false}
                      />
                    </SectionField>

                    <SectionField>
                      <EditSectionTitle
                        htmlFor={'map-alt-field'}
                        label={t('Alt text')}
                      />
                      <SectionInput
                        id={'map-alt-field'}
                        name={'map-alt-field'}
                        type="text"
                        placeholder={t('Add auxiliary text for screen reader')}
                        value={featureData.imageAlt || ''}
                        onChange={(e) => {
                          handleFeatureChange('imageAlt', e.target.value);
                        }}
                      />
                    </SectionField>
                    <SectionField>
                      <EditSectionTitle
                        htmlFor={'map-proposal-description-field'}
                        label={t('Description')}
                      />
                      <StyledTextAreaInput
                        id={'map-proposal-description-field'}
                        maxCharactersAllowed={130}
                        placeholder={t('Type here your description')}
                        value={featureData?.description || ''}
                        setValue={(value) => {
                          handleFeatureChange('description', value);
                        }}
                      />
                    </SectionField>
                    <SectionField>
                      <EditSectionTitle
                        htmlFor={'map-link-field'}
                        label={t('External link')}
                      />
                      <SectionInput
                        id={'map-link-field'}
                        name={'map-link-field'}
                        type="text"
                        placeholder={t('External link URL')}
                        value={featureData.externalLink || ''}
                        onChange={(e) => {
                          handleFeatureChange('externalLink', e.target.value);
                        }}
                        status={getErrorForField(
                          `map-edit/layers/shapes/${editingFeature.id}/hover-panel/link-url`
                        )}
                      />
                    </SectionField>
                  </>
                )}
              </Section>
            </ContentLeftSide>
            <ContentRightSide>
              <PreviewItemSection>
                <h5>{t('Hover panel preview')}</h5>
                <p>
                  {t(
                    'Use the left panel to adjust and save it to confirm your changes'
                  )}
                </p>
              </PreviewItemSection>
              <PreviewContent>
                <PreviewContainer>
                  <CustomLayerPreview
                    properties={featureData}
                    highlightedProposal={null}
                    handleClick={() => {}}
                  />
                </PreviewContainer>
              </PreviewContent>
            </ContentRightSide>
          </ModalContent>
          <ButtonsSection>
            {validated &&
            !errors.filter((e) =>
              e.field.includes(
                `map-edit/layers/shapes/${editingFeature.id}/hover-panel`
              )
            )?.length ? (
              <MapEditButton
                onClick={() => {
                  setHoverPanelModalOpen(false);
                  setValidated(false);
                }}
              >
                {t('Close')}
              </MapEditButton>
            ) : (
              <MapEditButton
                onClick={() => {
                  validateHoverPanelFields();
                  setValidated(true);
                }}
              >
                {t('Apply changes')}
              </MapEditButton>
            )}
          </ButtonsSection>
        </HoverPanelModal>
      </Wrapper>
      {deleteShapeModalOpen && (
        <DeleteFeatureModal
          title={t('Are you sure you want to delete this shape?')}
          open={deleteShapeModalOpen}
          onClose={() => setDeleteShapeModalOpen(false)}
          data-testid="ConfirmationModal"
        >
          <DeleteFeatureContent>
            <p>
              {t(
                'This action is permanent. Once you delete this shape, you will not be able to restore it.'
              )}
            </p>
            <div>
              <CancelButton onClick={() => setDeleteShapeModalOpen(false)}>
                {t('Cancel')}
              </CancelButton>
              <DeleteCancelButton
                onClick={() => {
                  deleteShape();
                }}
              >
                {t('Delete')}
              </DeleteCancelButton>
            </div>
          </DeleteFeatureContent>
        </DeleteFeatureModal>
      )}
    </>
  );
};
