import { gql } from '@apollo/client';
import { captureException } from '@sentry/node';
import getClient from 'Shared/utils/apollo-client';

const GET_AGREEMENTS_AND_COMMENTS_COUNT_FOR_PROPOSAL = gql`
  query GetAgreementsAndCommentsCountForProposal(
    $proposalSlug: String!
    $projectId: String!
  ) {
    getAgreementsAndCommentsCountForProposal(
      proposalSlug: $proposalSlug
      projectId: $projectId
    ) {
      agreementsCount
      commentsCount
    }
  }
`;

export const getAgreementsAndCommentsCountForProposalGql = async ({
  proposalSlug,
  projectId,
  apiToken,
}: {
  proposalSlug: string;
  projectId: string;
  apiToken: string;
}) => {
  try {
    const client = getClient(apiToken);
    const data = await client.query({
      query: GET_AGREEMENTS_AND_COMMENTS_COUNT_FOR_PROPOSAL,
      variables: {
        proposalSlug,
        projectId,
      },
      context: {
        fetchOptions: {
          method: 'GET',
        },
      },
    });
    return data.data.getAgreementsAndCommentsCountForProposal;
  } catch (e) {
    console.error(e);
    captureException(e);
  }
};
