import { gql } from '@apollo/client';
import { captureException } from '@sentry/node';
import { ProjectSettings } from 'Shared/types/settings';
import getClient from 'Shared/utils/apollo-client';

export const UPDATE_PROJECT_SETTINGS = gql`
  mutation Mutation($projectId: String!, $data: JSON) {
    updateProjectSettings(projectId: $projectId, data: $data) {
      _id
      projectId
      defaultLocale
      locales
      name
      subdomain
      openGraph
    }
  }
`;

export const updateProjectSettingsGql = async ({
  apiToken,
  projectId,
  data,
}: {
  apiToken: string;
  projectId: string;
  data: Partial<ProjectSettings>;
}) => {
  try {
    const client = getClient(apiToken);
    const { data: res } = await client.mutate({
      mutation: UPDATE_PROJECT_SETTINGS,
      variables: {
        projectId,
        data,
      },
    });
    return res.updateProjectSettings;
  } catch (error) {
    console.error(error);
    captureException(
      `error in fetchProjectSettings @ fetchProjectSettings.ts : ${error}`
    );
    return null;
  }
};
