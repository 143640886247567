import styled from 'styled-components';

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  flex-wrap: wrap;
`;

export const PdfItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.125rem;
  border: 0.0625rem solid #d8d8d8;
  width: fit-content;
  white-space: nowrap;
  & > svg {
    padding: 0 0.5rem;
    width: 20%;
  }
  & > p {
    padding: 0 0.625rem;
  }
  & > a {
    padding: 0.625rem;
  }
`;
export const ImageItem = styled.img`
  height: 9.375rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const PdfFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

export const ImageFilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  flex-wrap: wrap;
`;

export const PDFClipperButton = styled.div`
  border: 0.125rem solid ${({ theme }) => theme.colors.green[500]};
  border-radius: 0.25rem;
  width: 20.875rem;
  height: 2.25rem;
  padding: 1.25rem 1.25rem;
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.green[500]};
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
`;
