import { gql } from '@apollo/client';
import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import getClient from 'Shared/utils/apollo-client';

type Params = {
  projectName: string;
  type: string | Array<string>;
  enabled?: boolean;
  fetchConsentsCountGqlEndpoint: boolean;
  apiToken: string;
};

export const GET_CONSENTS_COUNT = gql`
  query Query($getConsentsCountParams: GetConsentsCountParams) {
    getConsentsCount(getConsentsCountParams: $getConsentsCountParams)
  }
`;

export const fetchConsentsCount = async ({
  projectName,
  type,
  enabled = true,
  fetchConsentsCountGqlEndpoint,
  apiToken,
}: Params): Promise<{
  count: number;
}> => {
  try {
    let typeQuery = '';
    if (Array.isArray(type)) {
      type.forEach((type: string) => (typeQuery += `&type=${type}`));
    } else {
      typeQuery = `&type=${type}`;
    }

    if (fetchConsentsCountGqlEndpoint) {
      const gqlClient = getClient(apiToken);

      const { data } = await gqlClient.query({
        query: GET_CONSENTS_COUNT,
        variables: {
          getConsentsCountParams: {
            project: projectName,
            type,
            enabled,
          },
        },
        context: {
          fetchOptions: {
            method: 'GET',
          },
        },
      });

      return data?.getConsentsCount?.data;
    }

    const internalNextApiResult = await fetch(
      `/api/consent/count?project=${projectName}&${typeQuery}&enabled=${enabled}`
    );

    const jsonConsentsCount = await internalNextApiResult.json();
    return jsonConsentsCount?.data;
  } catch (error) {
    captureException(
      `Error in fetchConsentsCount @ fetchConsentsCount.ts: ${error}`
    );
    return;
  }
};
