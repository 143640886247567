import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { User } from 'Shared/types';
import { getUserByEmail } from './getUserByEmail.gql';

const getUserByEmailOld = async (email: string) => {
  if (!email || email === 'undefined') return undefined;
  const resUser = await fetch(`/api/user?email=${encodeURIComponent(email)}`);
  const jsonUser = await resUser.json();
  return jsonUser?.data;
};

export const fetchUserByEmail = async (
  email: string,
  userEndpointsOnGql: boolean,
  apiKey: string
): Promise<User> => {
  try {
    if (userEndpointsOnGql) {
      return await getUserByEmail(apiKey, email);
    }
    /* This needs to be deprecated once confirmed the feature above works as intended */
    return await getUserByEmailOld(email);
  } catch (err) {
    captureException(err);
    return undefined;
  }
};
