import fetch from 'isomorphic-unfetch';
import { User } from 'Shared/types/user';
import { updateUser as updateUserGql } from './updateUser.gql';
type UpdateUserParams = {
  userId: string;
  updates: Partial<User>;
  userEndpointsOnGql: boolean;
  apiToken: string;
};

const updateUserOldWay = async (userId: string, updates: Partial<User>) => {
  const resNewUser = await fetch(`/api/user/${userId}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updates),
  });
  const jsonUser = await resNewUser.json();
  return jsonUser?.user;
};

// @TODO secure this req!!
export const updateUser = async ({
  userId,
  updates,
  userEndpointsOnGql,
  apiToken,
}: UpdateUserParams): Promise<User> => {
  if (userEndpointsOnGql) {
    return await updateUserGql(apiToken, userId, updates);
  }
  /* This needs to be deprecated once confirmed the feature above works as intended */
  return updateUserOldWay(userId, updates);
};
