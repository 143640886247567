import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { MixpanelEventTypes } from 'Client/utils/hooks';
import { mixpanel } from 'Client/utils/hooks/useAnalytics/mixpanel';
import { ProjectGaudi } from 'Shared/types';
import { Contribution, ContributionGaudi } from 'Shared/types/contribution';
import { getUserById } from '../user/findUserById.gql';

type ContrResponse = {
  success: boolean;
  message: string;
  contributions?: Array<string>;
};

export const updateContributions = async (body: {
  ids: Array<string>;
  updates: Partial<Contribution>;
  apiToken: string;
}): Promise<ContrResponse> => {
  try {
    const response = await fetch(`/api/contributions`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const jsonContributions = await response.json();
    const project = (await fetch(
      `/api/projects/${jsonContributions.projectId}`
    ).then((res) => res)) as ProjectGaudi;
    if (project?.features?.trackContributionFlow) {
      const user = await getUserById(jsonContributions.userId, body.apiToken);
      mixpanel(user, project, 'en-GB').trackEvent(
        MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW,
        {
          fileName: 'src/client/services/contributions/updateContributions.ts',
          functionName: 'updateContributions',
          database: 'acorn',
          fieldToBeUpdated: Object.keys(jsonContributions),
          gaudiUpdate: null,
          acornUpdate: {
            ids: body.ids,
            data: jsonContributions as unknown as Record<string, unknown>,
          },
          userId: jsonContributions.userId,
          demographicsId: jsonContributions?.demographicsId,
          contributionId: jsonContributions._id,
        }
      );
    }
    return jsonContributions;
  } catch (err) {
    captureException(err);
  }
};

export const updateGaudiContributions = async (body: {
  ids: Array<string>;
  updates: Partial<ContributionGaudi>;
  apiToken: string;
}): Promise<ContrResponse> => {
  try {
    const response = await fetch(`/api/contributions?originDb=gaudi`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const jsonContributions = await response.json();
    const project = (await fetch(
      `/api/projects/${jsonContributions.projectId}`
    ).then((res) => res)) as ProjectGaudi;
    if (project?.features?.trackContributionFlow) {
      const user = await getUserById(jsonContributions.userId, body.apiToken);
      mixpanel(user, project, 'en-GB').trackEvent(
        MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW,
        {
          fileName: 'src/client/services/contributions/updateContributions.ts',
          functionName: 'updateGaudiContributions',
          database: 'acorn',
          fieldToBeUpdated: Object.keys(jsonContributions),
          gaudiUpdate: {
            ids: body.ids,
            data: jsonContributions as unknown as Record<string, unknown>,
          },
          acornUpdate: null,
          userId: jsonContributions.userId,
          demographicsId: jsonContributions?.demographicsId,
          contributionId: jsonContributions._id,
        }
      );
    }
    return jsonContributions;
  } catch (err) {
    captureException(err);
  }
};
