import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { captureException } from '@sentry/node';
import { usePermissions, useProject, useUser } from 'Client/utils/hooks';
import { LabelValueOptions } from 'Shared/types/question';
import { Permissions } from 'Client/constants/permissions';
import {
  GET_CUSTOMER_USER_ADMIN_PAGES,
  GET_CUSTOMERS,
  GET_CUSTOMER_PROJECTS,
  GET_PROJECT_PAGES,
} from 'Client/pages/edit/components/DuplicateTileModal/getAdminPages.gql';
import {
  EditSectionTitle,
  RoundedDropdown,
} from 'Client/pages/edit/components/Form';
import {
  FormItem,
  FormSection,
  Wrapper,
} from './customerProjectPageSelector.styles';
interface CustomerProjectPageSelectorProps {
  pageTypes: string[];
  setSelectedPage: (page: {
    label: string;
    value: string;
    _id: string;
  }) => void;
  selectedCustomer?: Record<string, string>;
  setSelectedCustomer?: (customer: Record<string, string>) => void;
  selectedProject?: {
    label: string;
    value: string;
    _id: string;
    pages: { _id: string; slug: string; label: string; value: string }[];
  };
  setSelectedProject?: (project: Record<string, string>) => void;
  selectedPage?: Record<string, string>;
  currentMapPageId?: string;
}

export const CustomerProjectPageSelector = ({
  pageTypes,
  setSelectedPage,
  selectedCustomer,
  setSelectedCustomer,
  selectedProject,
  setSelectedProject,
  selectedPage,
  currentMapPageId = '',
}: CustomerProjectPageSelectorProps) => {
  const { t } = useTranslation('customer');
  const { user } = useUser();
  const { can } = usePermissions();
  const project = useProject();

  const [projectsData, setProjectsData] = React.useState([]);
  // const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  // const [selectedProject, setSelectedProject] = React.useState(null);
  // const [selectedPage, setSelectedPage] = React.useState(null);
  const [customerOptions, setCustomerOptions] = React.useState([]);
  const [projectOptions, setProjectOptions] = React.useState([]);
  const [pageOptions, setPageOptions] = React.useState([]);

  const [getCustomerUserAdminPages] = useMutation(
    GET_CUSTOMER_USER_ADMIN_PAGES
  );

  const [getCustomers] = useLazyQuery(GET_CUSTOMERS, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  const [getCustomerProjects] = useLazyQuery(GET_CUSTOMER_PROJECTS, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  const [getProjectPages] = useLazyQuery(GET_PROJECT_PAGES, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const canImportAllTiles = can(Permissions.IMPORT_ALL_TILES);

  const getCustomersData = async () => {
    const { data: customersData } = await getCustomers();
    const parsedCustomerOptions =
      customersData?.getCustomers.map((c) => ({
        label: c.customer,
        value: c._id,
      })) || [];
    setCustomerOptions(parsedCustomerOptions);
  };

  const getCustomerProjectsData = async (customerId: string) => {
    let customerIdFromRes = '';
    let customerProjectsData;
    let requestNumber = 0;
    do {
      const { data: _customerProjectsData } = await getCustomerProjects({
        variables: {
          customerId: customerId,
        },
      });
      customerIdFromRes = _customerProjectsData.getCustomerProjects._id;
      customerProjectsData = _customerProjectsData;
      requestNumber++;
    } while (customerIdFromRes !== customerId && requestNumber <= 2);
    requestNumber = 0;

    const parsedProjectOptions =
      customerProjectsData?.getCustomerProjects?.projects.map((p) => ({
        label: p.name,
        value: p._id,
      })) || [];
    return setProjectOptions(parsedProjectOptions);
  };
  const getProjectPagesData = async (projectId: string) => {
    if (!projectId) return;

    let projectIdFromRes = '';
    let pagesData;
    let requestNumber = 0;
    do {
      const { data: _pagesData } = await getProjectPages({
        variables: {
          getProjectPagesInput: {
            pageTypes: ['proposal', 'map'],
            projectId: projectId,
          },
        },
      });
      projectIdFromRes = _pagesData.getProjectPages._id;
      pagesData = _pagesData;
      requestNumber++;
    } while (projectIdFromRes !== projectId && requestNumber <= 2);
    requestNumber = 0;

    const parsedPagesData =
      pagesData?.getProjectPages?.pages.map((p) => ({
        label: p.slug,
        value: p._id,
      })) || [];
    const filteredPagesData = parsedPagesData?.filter(
      (page) => currentMapPageId ? (page.value !== currentMapPageId) : page.value
    );

    setPageOptions(filteredPagesData);
  };

  const handleCustomerChange = async (customerId: string) => {
    if (!customerId) return;
    return await getCustomerProjectsData(customerId);
  };

  React.useEffect(() => {
    /* Clear previous options */
    setProjectOptions([]);
    setSelectedProject(null);
    setPageOptions([]);
    setSelectedPage(null);
    /* Fetch new options */
    handleCustomerChange(selectedCustomer?.value);
  }, [selectedCustomer?.value]);

  React.useEffect(() => {
    if (projectOptions.length && selectedProject?.value) {
      handleProjectChange(selectedProject?.value);
    }
  }, [projectOptions]);
  const handleProjectChange = async (projectId: string) => {
    if (!projectId) return;
    if (canImportAllTiles) {
      const project = projectOptions.find((opt) => opt.value === projectId);
      setSelectedProject({ ...project, _id: project?.value });
      setPageOptions([]);
      await getProjectPagesData(projectId);
      return;
    }
    setSelectedProject(projectsData.find(({ _id }) => _id === projectId));
    await getProjectPagesData(projectId);
    return;
  };
  const handlePageChange = (pageId: string) => {
    if (canImportAllTiles) {
      const page = pageOptions.find(({ value }) => value === pageId);
      return setSelectedPage(page ? { ...page, _id: page.value } : null);
    }
    return setSelectedPage(
      selectedProject.pages.find(({ _id }) => _id === pageId)
    );
  };
  React.useEffect(() => {
    (async () => {
      try {
        if (!canImportAllTiles) {
          const res = await getCustomerUserAdminPages({
            variables: {
              getCustomerUserAdminPagesInput: {
                userEmail: user.email,
                userId: user._id,
                customerId: project.customerId,
                pageTypes,
                superRole: canImportAllTiles,
              },
            },
          });
          return setProjectsData(res?.data?.getCustomerUserAdminPages || []);
        }
        await getCustomersData();
      } catch (e) {
        captureException(e);
      }
    })();
  }, [getCustomerUserAdminPages, open, project.customer, user._id, user.email]);

  React.useEffect(() => {
    if (!canImportAllTiles) {
      const projectOptions: LabelValueOptions[] = projectsData.map(
        ({ _id, name }) => ({
          value: _id,
          label: name,
        })
      );
      const pageOptions: LabelValueOptions[] = selectedProject?.pages
        ?.map(({ _id, slug }) => ({
          value: _id,
          label: slug,
        }))
        ?.filter((page) => {
          return currentMapPageId ? (String(page.value) !== String(currentMapPageId)) : page.value});

      setProjectOptions(projectOptions || []);
      setPageOptions(pageOptions || []);
      return;
    }
  }, [projectsData, selectedProject]);

  return (
    <Wrapper>
      <FormSection>
        {canImportAllTiles && (
          <FormItem>
            <EditSectionTitle
              htmlFor="select-project-dropdown-label"
              label={t('Select the customer')}
            />
            <RoundedDropdown
              id="select-customer-dropdown"
              data-testid="select-customer-dropdown"
              name="select-customer-dropdown"
              options={customerOptions}
              value={customerOptions.find(
                (opt) => opt.value == selectedCustomer?.value
              )}
              width={'100%'}
              placeholder={t('Select a customer')}
              handleChange={(data) => {
                const value = data?.value || null;
                setSelectedCustomer(
                  customerOptions.find(
                    ({ value: optionValue }) => optionValue === value
                  )
                );
              }}
            />
          </FormItem>
        )}
        <FormItem>
          <EditSectionTitle
            htmlFor="select-project-dropdown-label"
            label={t('Select the project')}
          />
          <RoundedDropdown
            disabled={canImportAllTiles ? !selectedCustomer : false}
            id="select-project-dropdown"
            data-testid="select-project-dropdown"
            name="select-project-dropdown"
            options={projectOptions}
            value={projectOptions.find(
              (opt) =>
                opt.value == selectedProject?._id ||
                opt.value == selectedProject?.value
            )}
            width={'100%'}
            placeholder={t('Select a project')}
            handleChange={(e) => {
              handleProjectChange(e?.value);
            }}
          />
        </FormItem>
        <FormItem>
          <EditSectionTitle
            htmlFor="select-tile-dropdown-label"
            label={t('Import from tile')}
          />
          <RoundedDropdown
            disabled={!selectedProject}
            id="select-tile-dropdown"
            data-testid="select-tile-dropdown"
            name="select-tile-dropdown"
            options={pageOptions}
            value={
              pageOptions.find((opt) => opt.value == selectedPage?._id) ||
              pageOptions.find((opt) => opt.value == selectedPage?.value)
            }
            width={'100%'}
            placeholder={t('Select a page')}
            handleChange={(e) => {
              handlePageChange(e?.value);
            }}
          />
        </FormItem>
      </FormSection>
    </Wrapper>
  );
};
