import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { useProject, useUser } from 'Client/utils/hooks';
import {
  fetchProjectSettings,
  updateProjectSettings,
} from 'Client/services/project';
import {
  createNewSmartcatProject,
  requestDocumentTranslation,
} from 'Client/services/translation';
import { postChannelMessage } from 'Client/services/slack';
import { translationRequestedNotification } from 'Client/utils/slack/formatters';
import { Channel } from 'Shared/types/slack';
import { ProjectSettings } from 'Shared/types/settings';
import { isErrorObject } from 'Client/utils/guards';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { EditToolsButton } from '../EditModeTools/EditModeTools.styles';
import { TranslationModal } from './TranslationModal';
import { targetOptionsConfig } from './constants';
import {
  SmartCatTranslationProps,
  TargetOptionType,
  TargetOptionConfig,
} from './types';
import { buildMessageBody, buildProjectConfig } from './utils';
import { TranslationEditModeButton } from './Translations.styles';

export const Translation: React.FC<SmartCatTranslationProps> = ({
  currentView,
}: SmartCatTranslationProps) => {
  const { t } = useTranslation('customer');
  const project = useProject();
  const { user } = useUser();
  const { apiToken } = useUtils();

  const [options, setOptions] = React.useState<TargetOptionConfig>(
    targetOptionsConfig(t)
  );
  const [projectSettings, setProjectSettings] =
    React.useState<ProjectSettings>(null);
  const [modalShown, setModalShown] = React.useState<boolean>(false);
  const [infoString, setInfoString] = React.useState<string>('');

  React.useEffect(() => {
    fetchProjectSettings({
      projectId: project._id,
      projectRequestsOnGql: project.features.projectRequestsOnGql,
      apiToken,
    }).then((settings) => {
      settings && setProjectSettings(settings);
    });
  }, []);

  const handleClose = () => {
    setInfoString('');
    setModalShown(false);
  };

  const handleToggle = (optionType: TargetOptionType) => {
    const data = options;

    data[optionType].enabled = !data[optionType].enabled;

    setOptions({ ...data });
    setInfoString('');
  };

  const showModal = () => setModalShown(true);

  const createProject = async () => {
    const scProject = await createNewSmartcatProject(
      buildProjectConfig(projectSettings)
    );

    if (isErrorObject(scProject)) {
      return undefined;
    }

    const settings = await updateProjectSettings({
      projectId: projectSettings.projectId,
      data: { smartcatProjectId: scProject.id },
      projectRequestsOnGql: project.features.projectRequestsOnGql,
      apiToken,
    });
    if (!settings) {
      return undefined;
    }

    setProjectSettings(settings);

    return settings;
  };

  const submitRequest = async () => {
    let settings = projectSettings;

    // If the project settings do not yet include a smartcat project id, we will
    // just create a new smartcat project (unique for the commonplace project)
    // and then proceed as before...
    if (!projectSettings?.smartcatProjectId) {
      settings = await createProject();

      // If the creation failed somewhere, show a support info message and exit.
      if (!settings) {
        return setInfoString(
          'Please contact Commonplace support to set up connection to SmartCat translation service'
        );
      }
    }

    const selectedOptions = Object.values(options).filter(
      (option) => option.enabled
    );

    if (selectedOptions.length === 0) {
      return setInfoString(t('Please select at least one option'));
    }

    let success;

    const context = {
      document: currentView,
      options: selectedOptions,
      settings,
      user,
      href: window?.location?.href || '',
    };

    try {
      success = await requestDocumentTranslation(buildMessageBody(context));
    } catch {
      success = false;
    }

    setInfoString(
      success
        ? t('Submission accepted')
        : t('Sorry, something went wrong, please try again')
    );

    if (success) {
      await postChannelMessage({
        channel: Channel.MULTI_LINGUAL_CPS,
        message: translationRequestedNotification(context),
        project,
      });
    }

    return setTimeout(handleClose, 2000);
  };

  if (!project.features.smartCat) return null;

  const invokeButton = (
    <TranslationEditModeButton
      inverse
      onClick={showModal}
      data-onboarding={'edit-mode-translate'}
    >
      {t('Translate')}
    </TranslationEditModeButton>
  );

  const submitButton = (
    <EditToolsButton
      inverse
      onClick={submitRequest}
      data-onboarding={'edit-mode-requested-translation'}
    >
      {t('Submit')}
    </EditToolsButton>
  );

  const infoMessage = <Typography variant="body2">{infoString}</Typography>;

  return (
    <>
      {invokeButton}
      {modalShown && (
        <TranslationModal
          locales={projectSettings?.locales || []}
          open={modalShown}
          options={options}
          onClose={handleClose}
          onToggle={handleToggle}
        >
          {infoString ? infoMessage : submitButton}
        </TranslationModal>
      )}
    </>
  );
};
