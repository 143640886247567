import fetch from 'isomorphic-unfetch';
import { User } from 'Shared/types/user';
import { createUserGql } from './createUser.gql';

const createUserOldWay = async (body: Partial<User>) => {
  const resNewUser = await fetch('/api/user', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const jsonUser = await resNewUser.json();
  return jsonUser?.data;
};

// @TODO secure this req!!
export const createUser = async (
  body: Partial<User>,
  userEndpointsOnGql: boolean,
  apiToken: string
): Promise<User> => {
  if (userEndpointsOnGql) {
    return await createUserGql(body, apiToken);
  }
  return createUserOldWay(body);
};
