import { captureException } from '@sentry/node';
import { Contribution } from 'Shared/types/contribution';
import { updateUser } from '../user';
import { fetchContributionById } from '.';
import { getUserById } from '../user/findUserById.gql';

export const verifyEmailAssignedToContribution = async (
  emailValue: string,
  contrId: string,
  userEndpointsOnGql: boolean,
  apiToken: string
): Promise<boolean> => {
  const contr = (await fetchContributionById(contrId)) as Contribution;
  if (!contr.userId) {
    return false;
  }
  const existingUserEmail = (await getUserById(contr.userId, apiToken))?.email;
  if (existingUserEmail === emailValue) {
    return true;
  } else {
    // user has changed email half way through
    try {
      await updateUser({
        userId: contr.userId,
        updates: {
          deleted: `Acorn contribution flow email renamed on ${new Date().toISOString()}`,
        },
        userEndpointsOnGql,
        apiToken,
      });
    } catch (error) {
      captureException(
        `error in verifyEmailAssignedToContribution @ verifyEmailAssignedToContribution.ts : ${error}`
      );
    }
    return false;
  }
};
