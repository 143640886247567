import * as React from 'react';
import { DrawShapeTypes } from 'Shared/types/map';
import {
  AddACircleIcon,
  AddALineIcon,
  HearthPinIcon,
  AddAPolygonIcon,
  AddAShapeFileIcon,
} from 'Atoms/Icons';

export const FeatureIcon = ({
  type,
  props,
}: {
  type: DrawShapeTypes;
  props?: { width?: number; height?: number; color?: string };
}) => {
  const _props = props || {
    width: 15,
    height: 15,
  };
  switch (type) {
    case DrawShapeTypes.POINT:
      return <HearthPinIcon {..._props} />;
    case DrawShapeTypes.LINE:
      return <AddALineIcon {..._props} />;
    case DrawShapeTypes.MULTI_LINE:
      return <AddALineIcon {..._props} />;
    case DrawShapeTypes.POLYGON:
      return <AddAPolygonIcon {..._props} />;
    case DrawShapeTypes.CIRCLE:
      return <AddACircleIcon {..._props} />;
    case DrawShapeTypes.SHAPEFILE:
      return <AddAShapeFileIcon {..._props} />;
    default:
      return <HearthPinIcon {..._props} />;
  }
};
