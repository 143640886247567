export const PRIVACY_POLICY_URL = 'https://www.commonplace.is/privacy-policy';
export const COMMONPLACE_URL = 'https://www.commonplace.is/';
export const PROFILE_URL = 'https://profile.commonplace.is/';
export const MASTER_DASHBOARD_URL = 'https://dashboard.commonplace.is/';
export const COMMONPLACE_URL_UTM_DEMOGRAPHICS =
  'https://www.commonplace.is/privacy-policy?utm_campaign=2.0DemographicsPage';
export const PRIVACY_POLICY_URL_UTM_DEMOGRAPHICS =
  'https://www.commonplace.is/privacy-policy?utm_campaign=2.0DemographicsPage';
export const CLOUDINARY_BASE_URL =
  'https://res.cloudinary.com/commonplace-digital-limited/image/upload/';
export const COMMONPLACE_DEFAULT_FAVICON = '/static/favicon-32x32.png';
export const COMMONPLACE_COOKIE_POLICY =
  'https://www.commonplace.is/cookie-policy';
export const COMMONPLACE_IN_ACTION =
  'https://www.commonplace.is/commonplace-in-action';
export const COMMONPLACE_TERMS = 'https://www.commonplace.is/terms';
export const COMMONPLACE_ACCEPTABLE_USE =
  'https://www.commonplace.is/acceptable-use';
export const COMMONPLACE_ABOUT = 'https://www.commonplace.is/about';
export const COMMONPLACE_GENERAL_ENQUIRIES =
  'https://www.commonplace.is/general-enquiries';
export const COMMONPLACE_FACEBOOK = 'https://www.facebook.com/commonplace.is/';
export const COMMONPLACE_TWITTER = 'https://twitter.com/Cmnplace';
export const COMMONPLACE_SHAPEFILE_DOC =
  'https://help-centre.commonplace.is/en/articles/217-how-to-add-layers-to-a-map';
export const ARRANGE_A_DEMO_URL = 'https://www.commonplace.is/demo';
export const ACCESSIBILITY_STATEMENT =
  'https://www.commonplace.is/accessibility-statement';
