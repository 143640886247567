import { gql } from '@apollo/client';
import { captureException } from '@sentry/node';
import { DynamicProposalsRules } from 'Shared/types/proposalPage';
import getClient from 'Shared/utils/apollo-client';

const GET_EXTERNAL_PROPOSALS = gql`
  query GetExternalProposals(
    $getExternalProposalsInput: getExternalProposalsInput
  ) {
    getExternalProposals(GetExternalProposalsInput: $getExternalProposalsInput)
  }
`;

export const getExternalProposalsGql = async ({
  rules,
  listOrder,
  language,
  unselectedProposals,
  editModeOptions,
  ignoreHidden,
  projectId,
  apiToken,
}: {
  rules: DynamicProposalsRules[];
  listOrder: string;
  language: string;
  apiToken: string;
  unselectedProposals: string[];
  editModeOptions: boolean;
  projectId: string;
  ignoreHidden: boolean;
}) => {
  try {
    const client = getClient(apiToken);
    const { data } = await client.query({
      query: GET_EXTERNAL_PROPOSALS,
      variables: {
        getExternalProposalsInput: {
          rules,
          order: listOrder,
          lang: language,
          unselectedProposals,
          editModeOptions,
          ignoreHidden,
          projectId,
        },
      },
      context: {
        fetchOptions: {
          method: 'GET',
        },
      },
    });
    return data.getExternalProposals;
  } catch (e) {
    console.error(e);
    captureException(e);
  }
};
