import { gql } from '@apollo/client';
import { ObjectId } from 'mongoose';
import { User } from 'Shared/types';
import getClient from 'Shared/utils/apollo-client';

export const UPDATE_USER = gql`
  mutation Mutation($userId: String!, $data: JSON!) {
    updateUser(userId: $userId, data: $data) {
      _id
      email
      status
      projects
      firstName
      lastName
      firstSeen
      language
    }
  }
`;

export const updateUser = async (
  apiToken: string,
  userId: string | ObjectId,
  updates: Partial<User>
) => {
  const client = getClient(apiToken);

  const { data } = await client.mutate({
    mutation: UPDATE_USER,
    variables: {
      userId: userId,
      data: updates,
    },
  });
  return data.updateUser;
};
