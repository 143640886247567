import { getAgreementsAndCommentsCountForProposalGql } from './getAgreementsAndCommentsCountForProposal.gql';

const getAgreementsAndCommentsCountForProposalOld = async ({
  proposalSlug,
}) => {
  const response = await fetch(
    `/api/external/proposals?proposalSlug=${proposalSlug}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
  );
  const data = await response.json();
  return data;
};
export const getAgreementsAndCommentsCountForProposal = async ({
  proposalSlug,
  projectId,
  externalProposalsRequestOnGql,
  apiToken,
}: {
  proposalSlug: string;
  projectId: string;
  externalProposalsRequestOnGql: boolean;
  apiToken: string;
}) => {
  if (externalProposalsRequestOnGql) {
    return await getAgreementsAndCommentsCountForProposalGql({
      proposalSlug,
      projectId,
      apiToken,
    });
  }
  /* This needs to be deprecated once confirmed the feature above works as intended */
  return await getAgreementsAndCommentsCountForProposalOld({ proposalSlug });
};
