import { Question } from 'Shared/types';
import { MapPageProps } from 'Client/pages';
import { ProposalSection } from 'Client/pages/proposals';
import { TileMap } from '../components/SectionPanel/tiles/types';
import { buildProposalForDb } from './buildProposalForDb';

type MapQuestions = Pick<
  MapPageProps,
  'sentimentQuestion' | 'questions' | 'steps'
>;

export const buildMapForDb = (
  blocks: Array<TileMap>,
  proposalExistingSteps: ProposalSection<Question>[]
): MapQuestions => {
  const orderedQuestions = blocks
    .filter((block) => block.component !== 'page-break')
    .map((block, index) => ({
      question: (block.data.content as Question)?.id,
      order: index,
    }));
  let questions = orderedQuestions;
  let sentimentQuestion = undefined;

  const sentiment = blocks.find(
    (block) => (block.data.content as Question).name === 'feeling'
  );
  const steps = buildProposalForDb(blocks, proposalExistingSteps);
  if (sentiment) {
    questions = orderedQuestions.filter(
      ({ question }) => question !== (sentiment.data.content as Question).id
    );
    sentimentQuestion = orderedQuestions.find(
      ({ question }) => question === (sentiment.data.content as Question).id
    );
  }

  return { sentimentQuestion, questions, steps };
};
