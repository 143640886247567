import * as React from 'react';
import { ErrorStatusOrHelperText } from 'Atoms';
import {
  TextArea,
  Wrapper,
  CharacterCounter,
  Count,
} from './TextAreaInput.styles';
import { TextAreaInputProps } from '../../types';

export const TextAreaInput = ({
  placeholder,
  maxCharactersAllowed = 200,
  value,
  setValue,
  id,
  status,
  ...props
}: TextAreaInputProps) => {
  return (
    <Wrapper {...props}>
      <TextArea
        id={id}
        placeholder={placeholder}
        onChange={(e) =>
          setValue(
            e.target.value.length > maxCharactersAllowed
              ? value
              : e.target.value
          )
        }
        value={value}
        statusType={status?.type}
      />
      <CharacterCounter>
        <Count percentage={(value.length / maxCharactersAllowed) * 100}>
          {value.length}
        </Count>
        / {maxCharactersAllowed}
      </CharacterCounter>
      {status && <ErrorStatusOrHelperText status={status} />}
    </Wrapper>
  );
};
