import * as React from 'react';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { captureException } from '@sentry/node';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import fetch from 'isomorphic-unfetch';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { LoadingButtonStates } from 'Atoms';
import { MapIcon, ArrowUpRight, ScreenIcon, SmartphoneIcon } from 'Atoms/Icons';
import {
  LanguageSelector,
  PreventRouteModal,
  AddProposalModal,
  ConfigureProposalModal,
  CheckFeelingQuestionModal,
  ValidationModal,
} from 'Organisms';
import {
  useProject,
  useUser,
  usePermissions,
  useMap,
  useEditModeContext,
} from 'Client/utils/hooks';
import { OptionItem } from 'Client/types';
import {
  EDITABLE_PAGE_TYPES,
  EDIT_LAYOUT_ACTIONS,
  METADATA_ACTIONS,
} from 'Pages/edit/constants';
import { SelectedViewOption } from 'Pages/edit/types';
import { preventRouteChange } from 'Pages/edit/utils';
import { RootState } from 'Client/redux-store';
import { updateStage } from 'Client/utils/reduxReducers/editMode/proposalViewReducer';
import { ProposalStage } from 'Client/pages/proposals';
import { COOKIES } from 'Client/constants/cookies';
import { Permissions } from 'Client/constants/permissions';
import { removeAll } from 'Client/utils/reduxReducers/editMode/validationReducer';
import { postChannelMessage } from 'Client/services/slack';
import { Channel } from 'Shared/types/slack';
import {
  AUDIT_TRAIL_ACTIONS,
  createAuditTrailLog,
} from 'Server/services/audit/CreateAuditTrailLog';
import { PageDropdown } from 'Client/pages/edit/components/PageDropdown';
import {
  EditToolsButton,
  EditToolsPreviewMethods,
  ProposalStageSelect,
  Toolbar,
  ButtonLabel,
  EditToolsLoadingButton,
  Label,
  LanguageDropdown,
  EditMapQuestionsButton,
  EditButtonsWrapper,
  PreviewPageButton,
  LeftSideSection,
  EditModePreviewMode,
  RightSideSection,
  EditModeSelector,
  ToolbarSection,
  Divider,
} from './EditModeTools.styles';
import { ActiveModalIndex, EditModeToolsProps } from './types';
import { getStageOptions } from './utils/getStageOptions';
import { Translation } from '../SmartCatTranslation';

export const EditModeTools: React.FC<EditModeToolsProps> = ({
  currentView,
  handleSave,
  editablePages,
  proposalViewsInitial,
  milestonesPageInitial,
  hubContentInitial,
  onLanguageChange,
  isProcessing,
  setIsProcessing,
  shouldBlockSaving,
  savePerformed,
  validated,
  validateClick,
  mapHasChanges,
}: EditModeToolsProps) => {
  const project = useProject();
  const router = useRouter();
  const client = useApolloClient();
  const { user } = useUser();
  const [cookies] = useCookies();
  const { t, i18n } = useTranslation('customer');
  const { can } = usePermissions();
  const dispatchRdx = useDispatch();
  const proposalBlocksRdx = useSelector(
    (state: RootState) => state.editModeProposalBlocks
  );

  const {
    state: { proposal: mapProposal },
  } = useMap();
  const { errors } = useSelector((s: RootState) => s.editModeValidation);
  const proposalViewRdx = useSelector((s: RootState) => s.editModeProposalView);
  const { feelingQuestion } = useSelector(
    (s: RootState) => s.editModeQuestions
  );
  const pageInitialState = proposalViewsInitial?.find(
    (pr) => `/${pr.slug}` === currentView.value
  );
  const { validateTiles } = useSelector((s: RootState) => s.editModeValidation);
  const [
    { metadata, milestonesPage, hubPage, editModeLayout },
    { dispatchMetadata, dispatchEditModeLayout },
  ] = useEditModeContext();
  const [proposalStageSelectItem, setProposalStageSelectItem] =
    React.useState<OptionItem | null>(null);
  // const [redirectRoute, setRedirectRoute] = React.useState<string>('');
  const [activeModal, setActiveModal] = React.useState<ActiveModalIndex>(
    ActiveModalIndex.NONE
  );
  const [hasChanges, setHasChanges] = React.useState(false);
  const canSwitchStage = [
    EDITABLE_PAGE_TYPES.PROPOSAL,
    EDITABLE_PAGE_TYPES.MAP,
  ];
  const isMapEditModeV2 = !!project.features.mapEditModeV2;
  const isMapPage = currentView.type === EDITABLE_PAGE_TYPES.MAP;
  const havePermissionToSwitchStage =
    !isMapPage || can(Permissions.EDIT_MODE_LAUNCH_MAPS);
  const hasQuestions =
    (proposalBlocksRdx?.[router.locale] || [])?.filter(
      (block) => block.component === 'question'
    )?.length > 0;
  const isProjectActive = project.stage === 'active';
  const isPageDraft = pageInitialState?.stage === 'draft';
  const isQuestionsEditor = metadata.editMapQuestions;
  const canByPassQa =
    isProjectActive &&
    isPageDraft &&
    can(Permissions.CAN_LAUNCH_PAGES_WITHOUT_QA);

  const canLaunchWithoutQA =
    canByPassQa || !isProjectActive || !isPageDraft || !hasQuestions;

  const canShowStageSelect =
    canSwitchStage.includes(currentView.type) && havePermissionToSwitchStage;
  React.useEffect(() => {
    if (
      canSwitchStage.includes(currentView.type) &&
      proposalViewRdx[router.locale]
    ) {
      setProposalStageSelectItem({
        label: proposalViewRdx[router.locale]?.stage,
        value: proposalViewRdx[router.locale]?.stage,
      });
    }
  }, [currentView.type, proposalViewRdx[router.locale]]);

  React.useEffect(() => {
    const shouldPreventRouteChange = preventRouteChange({
      currentView,
      proposalViewsInitial,
      proposalPage: proposalViewRdx[router.locale],
      milestonesPageInitial,
      milestonesPage,
      hubContentInitial,
      hubPage,
    });

    const doesItHasChanges = savePerformed
      ? !savePerformed
      : shouldPreventRouteChange;
    setHasChanges(doesItHasChanges);
  }, [
    currentView,
    proposalViewRdx[router.locale],
    proposalViewsInitial,
    milestonesPage,
    milestonesPageInitial,
    hubPage,
    hubContentInitial,
    isProcessing,
    savePerformed,
  ]);

  const requestQaForPage = async () => {
    try {
      const pageUrl = window.location.href;

      const requestMessage = `Page launch request\n${user.email} requests to launch a new page on ${project.name}\nPage URL: ${pageUrl}`;
      await postChannelMessage({
        channel: Channel.CUSTOMER_SUCCESS,
        message: requestMessage,
        project,
      });
      toast('Your QA request has been submitted.', {
        type: 'success',
      });
      handleSaveClick(() => continueRoute(''));
    } catch (err) {
      captureException(err);
    }
  };
  const resetSaveLoadState = () =>
    dispatchMetadata({
      type: METADATA_ACTIONS.SET_STATE_LOADING_BUTTON,
      payload: LoadingButtonStates.INITIAL,
    });
  const handleProposalStageSelect = ({ value }: SelectedViewOption) => {
    console.log('changing the page status: ', value);
    dispatchRdx(updateStage({ stage: value as ProposalStage }));
  };
  const continueRoute = (route: string): void => {
    router.push(route);
  };
  const closeModal = () => {
    setActiveModal(ActiveModalIndex.NONE);
  };
  const openModal = (kind: ActiveModalIndex) => () => {
    setActiveModal(kind);
  };
  const checkOnFeelingQuestion = () => {
    const hiddenByCookie = Boolean(
      cookies[COOKIES.HIDE_NO_SENTIMENT_WARNING] &&
        cookies[COOKIES.HIDE_NO_SENTIMENT_WARNING] === router.query?.slug
    );

    if (
      currentView.type === EDITABLE_PAGE_TYPES.PROPOSAL &&
      hasQuestions &&
      !feelingQuestion &&
      !hiddenByCookie
    ) {
      setActiveModal(ActiveModalIndex.CHECK_FEELING_QUESTION);
    } else {
      const invalidFields = Object.keys(validateTiles).filter(
        (key) => validateTiles[key]?.validate(validateTiles[key]?.validateData)
      );

      if (invalidFields.length > 0) {
        setActiveModal(ActiveModalIndex.INVALID);
        return;
      }

      dispatchRdx(removeAll());

      handleSaveClick(null);
    }
  };
  const saveAndContinue = () => {
    closeModal();
    // handleSaveClick(() => continueRoute(redirectRoute));
  };
  const closeModalAndSave = () => {
    closeModal();
    handleSaveClick(null);
  };
  const discardAndContinue = () => {
    closeModal();
    // continueRoute(redirectRoute);
  };
  const handleSaveClick = (callback: React.MouseEvent | (() => void)) => {
    if (
      [EDITABLE_PAGE_TYPES.PROPOSAL, EDITABLE_PAGE_TYPES.MAP].includes(
        currentView.type
      )
    ) {
      const isMap = currentView.type === EDITABLE_PAGE_TYPES.MAP;
      const stageProp = isMap ? mapProposal.stage : pageInitialState?.stage;
      const stageHasDifferences =
        proposalViewRdx[router.locale].stage !== stageProp;

      if (stageHasDifferences) {
        createAuditTrailLog({
          client,
          userId: user._id.toString(),
          project: project.id,
          projectId: project._id,
          projectStage: project.stage,
          pageId: currentView.pageId,
          fromStage: stageProp,
          toStage: proposalViewRdx[router.locale].stage,
          action: AUDIT_TRAIL_ACTIONS.CHANGE_PAGE_STAGE,
          date: new Date(),
        }).catch(captureException);
      }
    }

    dispatchMetadata({
      type: METADATA_ACTIONS.SET_STATE_LOADING_BUTTON,
      payload: LoadingButtonStates.LOADING,
    });

    handleSave(callback);
  };
  const handleRedirect = () => {
    const noEditModeRoute = router.asPath.substring(6); // removes the first /edit/ part
    router.push(`/${noEditModeRoute}`);
  };

  const handleExitClick = () => {
    if (!isMapEditModeV2 || (!mapHasChanges && !hasChanges)) {
      return handleRedirect();
    }
    alert('You have unsaved changes. Please save before exiting.');
  };
  const handleAddNewProposal = async (
    type: EDITABLE_PAGE_TYPES.PROPOSAL | EDITABLE_PAGE_TYPES.MAP,
    config: Record<string, string>
  ) => {
    try {
      const { slug } = config;
      setIsProcessing(true);
      const response = await fetch(`/api/pages/${type}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type,
          lang: router.locale,
          config,
          userId: user._id,
        }),
      });
      setIsProcessing(false);
      if (response.ok) {
        type === EDITABLE_PAGE_TYPES.PROPOSAL
          ? router.push(`/edit/proposals/${slug}`)
          : type === EDITABLE_PAGE_TYPES.MAP
          ? router.push(`/map/${slug}`)
          : null;
      }
    } catch (error) {
      captureException(
        `Error in handleAddNewProposal @ EditModeTools.tsx: ${error}`
      );
      setIsProcessing(false);
    }
  };
  const addNewProposal = async (config: Record<string, string>) => {
    const type =
      activeModal === ActiveModalIndex.CONFIGURE_PROPOSAL
        ? EDITABLE_PAGE_TYPES.PROPOSAL
        : activeModal === ActiveModalIndex.CONFIGURE_MAP_PROPOSAL
        ? EDITABLE_PAGE_TYPES.MAP
        : undefined;
    if (!type) return;
    await handleAddNewProposal(type, config);
    closeModal();
  };
  const switchMapEditor = () => {
    if ((hasChanges || mapHasChanges) && metadata.editMapQuestions) {
      return alert(
        'You have unsaved changes. Please save before switching editors.'
      );
    }
    dispatchMetadata({
      type: METADATA_ACTIONS.SET_EDIT_MAP_QUESTIONS,
      payload: !metadata.editMapQuestions,
    });
  };
  const getPreviewLink = (pageType: EDITABLE_PAGE_TYPES) => {
    switch (pageType) {
      case EDITABLE_PAGE_TYPES.DEMOGRAPHICS:
        return `${router.asPath.replace('edit', i18n.language)}`;
      default:
        return `/${router.asPath.substring(6)}`; // path without /edit/
    }
  };
  const handlePreviewModeChange = (layout: 'desktop' | 'mobile') => {
    return dispatchEditModeLayout({
      type: EDIT_LAYOUT_ACTIONS.SWITCH_DEVICE_PREVIEW,
      previewMode: layout,
    });
  };

  return (
    <Toolbar
      data-testid="EditModeTools"
      isMap={
        currentView.type === EDITABLE_PAGE_TYPES.MAP &&
        !metadata.editMapQuestions
      }
    >
      <ToolbarSection>
        <RightSideSection>
          <EditModeSelector data-onboarding="edit-mode-page-selector">
            <Label>{t('Page:')}</Label>
            <PageDropdown
              currentView={currentView}
              editablePages={editablePages}
              setActiveModal={setActiveModal}
            />
          </EditModeSelector>
          {canLaunchWithoutQA ? (
            canShowStageSelect && (
              <EditModeSelector data-testId="edit-mode-stage-select">
                <Label>{t('Status:')}</Label>
                <ProposalStageSelect
                  noFocusHighlight
                  value={proposalStageSelectItem}
                  isClearable={false}
                  handleChange={handleProposalStageSelect}
                  options={getStageOptions(
                    proposalViewRdx[router.locale]?.stage
                  )}
                  isDisabled={isProcessing}
                />
              </EditModeSelector>
            )
          ) : (
            <div data-testId="edit-mode-request-qa-button">
              <EditToolsButton
                inverse
                onClick={requestQaForPage}
                style={{ width: '16rem' }}
              >
                {t('Request QA for page')}
              </EditToolsButton>
            </div>
          )}
          {currentView.type != EDITABLE_PAGE_TYPES.MAP && (
            <>
              <EditModePreviewMode data-testId="edit-mode-preview-desktop-layout">
                <EditToolsPreviewMethods
                  data-testId="edit-mode-preview-button-selection"
                  data-onboarding="edit-mode-mobile-preview-button"
                  onClick={() => handlePreviewModeChange('mobile')}
                  active={editModeLayout.previewMode === 'mobile'}
                  aria-active={editModeLayout.previewMode === 'mobile'}
                >
                  <SmartphoneIcon />
                </EditToolsPreviewMethods>
                <Divider />
                <EditToolsPreviewMethods
                  data-testId="edit-mode-preview-button-selection"
                  data-onboarding="edit-mode-desktop-preview-button"
                  onClick={() => handlePreviewModeChange('desktop')}
                  active={editModeLayout.previewMode === 'desktop'}
                  aria-active={editModeLayout.previewMode === 'desktop'}
                >
                  <ScreenIcon />
                </EditToolsPreviewMethods>
              </EditModePreviewMode>
            </>
          )}
          {currentView.type === EDITABLE_PAGE_TYPES.MAP && (
            <>
              {metadata.editMapQuestions ? (
                <EditMapQuestionsButton onClick={switchMapEditor}>
                  <MapIcon width={17} height={17} />
                  {t('Edit map')}
                </EditMapQuestionsButton>
              ) : (
                !project.features.mapEditModeV2 && (
                  <EditMapQuestionsButton onClick={switchMapEditor}>
                    {t('Edit questions')}{' '}
                  </EditMapQuestionsButton>
                )
              )}
            </>
          )}
        </RightSideSection>
        <LeftSideSection>
          {project?.features?.i18n && (
            <LanguageDropdown>
              <LanguageSelector
                isEditMode
                editModeCallback={onLanguageChange}
              />
            </LanguageDropdown>
          )}
          <EditButtonsWrapper>
            {router.locale === 'en-GB' && (
              <Translation currentView={currentView} />
            )}
            <EditToolsButton
              link
              onClick={handleExitClick}
              style={{ padding: '0 1.5rem' }}
              data-onboarding={'edit-mode-exit'}
            >
              <ButtonLabel>{t('Exit')}</ButtonLabel>
            </EditToolsButton>
            {isMapPage && isMapEditModeV2 && !isQuestionsEditor ? (
              <>
                {validated && !errors.length ? (
                  <EditToolsLoadingButton
                    inverse
                    state={metadata.buttonLoading}
                    resetState={resetSaveLoadState}
                    onClick={checkOnFeelingQuestion}
                    disabled={isProcessing || shouldBlockSaving}
                    data-onboarding={'edit-mode-save'}
                    hasErrors={!!errors?.length}
                  >
                    {t('Save')}
                  </EditToolsLoadingButton>
                ) : (
                  <EditToolsButton
                    onClick={() => {
                      validateClick();
                    }}
                    inverse
                    hasErrors={!!errors?.length}
                  >
                    {t('Apply changes')}
                  </EditToolsButton>
                )}
              </>
            ) : (
              <EditToolsLoadingButton
                inverse
                state={metadata.buttonLoading}
                resetState={resetSaveLoadState}
                onClick={checkOnFeelingQuestion}
                disabled={isProcessing || shouldBlockSaving}
                data-onboarding={'edit-mode-save'}
                hasErrors={!!errors?.length}
              >
                {t('Save')}
              </EditToolsLoadingButton>
            )}
            <PreviewPageButton
              href={getPreviewLink(currentView.type)}
              disabled={hasChanges || mapHasChanges}
              target="_blank"
              inverse
              data-onboarding={'edit-mode-preview'}
            >
              <ArrowUpRight width={21} height={21} />
              {t('Preview')}
            </PreviewPageButton>
          </EditButtonsWrapper>
        </LeftSideSection>
      </ToolbarSection>
      <ToolbarModals
        activeModal={activeModal}
        closeModal={closeModal}
        saveAndContinue={saveAndContinue}
        closeModalAndSave={closeModalAndSave}
        discardAndContinue={discardAndContinue}
        openModal={openModal}
        addNewProposal={addNewProposal}
        editablePages={editablePages}
        currentSlug={router.query?.slug}
      />
    </Toolbar>
  );
};

const ToolbarModals = ({
  activeModal,
  closeModal,
  saveAndContinue,
  closeModalAndSave,
  discardAndContinue,
  openModal,
  addNewProposal,
  editablePages,
  currentSlug,
}) => {
  const isActiveModal = (kind: ActiveModalIndex[]) => {
    return kind.includes(activeModal);
  };
  return (
    <>
      <PreventRouteModal
        open={isActiveModal([ActiveModalIndex.REDIRECT_ROUTE])}
        onClose={closeModal}
        onSave={saveAndContinue}
        onDiscard={discardAndContinue}
      />
      <CheckFeelingQuestionModal
        open={isActiveModal([ActiveModalIndex.CHECK_FEELING_QUESTION])}
        onClose={closeModal}
        onSave={closeModalAndSave}
        onDiscard={closeModal} // on discard this modal just closes and stays in edit mode
        currentSlug={currentSlug}
      />
      <AddProposalModal
        open={isActiveModal([ActiveModalIndex.ADD_PROPOSAL])}
        modalKind={activeModal}
        onInit={openModal}
        onCancel={closeModal}
        onClose={closeModal}
      />
      <ConfigureProposalModal
        open={isActiveModal([
          ActiveModalIndex.CONFIGURE_PROPOSAL,
          ActiveModalIndex.CONFIGURE_MAP_PROPOSAL,
        ])}
        modalKind={activeModal}
        references={editablePages.map((p) => p.value.replace(/^\//, ''))}
        onCancel={openModal(ActiveModalIndex.ADD_PROPOSAL)}
        onClose={closeModal}
        onCreate={addNewProposal}
      />
      <ValidationModal
        open={isActiveModal([ActiveModalIndex.INVALID])}
        onClose={closeModal}
      />
    </>
  );
};
