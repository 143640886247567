import { gql } from '@apollo/client';
import { ObjectId } from 'mongoose';
import { User } from 'Shared/types';
import getClient from 'Shared/utils/apollo-client';

export const GET_USER_BY_ID = gql`
  query GetUserById($getUserByIdInput: String!) {
    getUserById(GetUserByIdInput: $getUserByIdInput) {
      _id
      email
      status
      projects
      firstName
      lastName
      firstSeen
      language
    }
  }
`;

export const getUserById = async (
  userId: string | ObjectId,
  apiToken: string
): Promise<User> => {
  const client = getClient(apiToken);

  const { data } = await client.mutate({
    mutation: GET_USER_BY_ID,
    variables: {
      getUserByIdInput: userId,
    },
  });
  return data.getUserById;
};
