import fetch from 'isomorphic-unfetch';
import { getUserByEmail } from './getUserByEmail.gql';
import { getUserById } from './findUserById.gql';

type getBlockedDataProps = {
  email?: string;
  id?: string;
  userEndpointOnGql: boolean;
  apiToken: string;
};

type BlockedData = {
  success: boolean;
  blocked?: Record<string, unknown>;
};

const getBlockedDataOldWay = async (email: string, id: string) => {
  const queryParams = email
    ? `?email=${encodeURIComponent(email)}`
    : `?id=${encodeURIComponent(id)}`;
  const result = await fetch(`/api/user/blocked${queryParams}`, {
    method: 'GET',
  });
  const json: BlockedData = await result.json();
  return json;
};
export const getBlockedData = async ({
  email,
  id,
  userEndpointOnGql,
  apiToken,
}: getBlockedDataProps) => {
  if (userEndpointOnGql) {
    if (email) {
      const user = await getUserByEmail(email, apiToken);
      const isBlocked = user && user.blocked && !user.blocked.unblocked;
      return { success: !!user, blocked: isBlocked };
    }
    if (id) {
      const user = await getUserById(id, apiToken);
      const isBlocked = user && user.blocked && !user.blocked.unblocked;
      return { success: !!user, blocked: isBlocked };
    }
    return { success: false };
  }
  /* This needs to be deprecated once confirmed the feature above works as intended */
  return getBlockedDataOldWay(email, id);
};
