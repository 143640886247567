import * as React from 'react';
import { captureException } from '@sentry/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { IconQuestionMark } from '@tabler/icons-react';
import { theme } from 'Theme';
import { SvgRenderer } from 'Molecules';
import {
  useAnalytics,
  MixpanelEventTypes,
  useProject,
} from 'Client/utils/hooks';
import { fetchProjectSettings } from 'Client/services/project';
import {
  MilestoneIcon,
  HubIcon,
  NewsIcon,
  ProposalsIcon,
  TeamIcon,
  FaqIcon,
} from 'Icons';
import { NavBarIcons } from 'Atoms/Icons/NavBar';
import { useUtils } from 'Client/utils/hooks/useUtils';
import type { MenuItem, Props } from './types';
import { BottomNavigation, NavigationItem } from './ProjectNavigation.styles';

const iconMap = {
  hub: <HubIcon />,
  milestones: <MilestoneIcon />,
  news: <NewsIcon />,
  proposals: <ProposalsIcon />,
  team: <TeamIcon />,
  faq: <FaqIcon />,
  ...NavBarIcons,
};

const ProjectNavigation: React.FC<Props> = ({
  menuItems,
  showLabels = true,
}) => {
  const { t } = useTranslation();
  const project = useProject();
  const { features } = project;
  const { apiToken } = useUtils();
  const navbarV2 = !!features.navbarV2;
  const router = useRouter();
  const { trackEvent } = useAnalytics();
  const { _id } = useProject();
  const [navItems, setNavItems] = React.useState(menuItems || []);
  const trackClick = (name: string): void => {
    trackEvent(MixpanelEventTypes.CLICKED_PROJECT_NAVIGATION, {
      name,
    });
  };

  React.useEffect(() => {
    const getProjectSettings = async () => {
      try {
        if (menuItems.length) {
          return setNavItems(menuItems);
        }
        const settings = await fetchProjectSettings({
          projectId: _id,
          projectRequestsOnGql: project.features.projectRequestsOnGql,
          apiToken,
        });

        if (navbarV2) {
          const navVersion = settings.navbar[router.locale][0];
          const { version } = await fetch(`/api/versions/${navVersion}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          }).then((x) => x.json());
          return setNavItems(version.items.filter((item) => !!item.active));
        }
        const settingsNav = settings?.nav;
        const navByLocale = settingsNav[router.locale];
        if (settingsNav && navByLocale) {
          return setNavItems(navByLocale);
        }
      } catch (error) {
        captureException(
          `error in getProjectSettings @ ProjectNavigation.tsx : ${error}`
        );
      }
    };
    getProjectSettings();
  }, [_id, menuItems, router.locale]);
  /* We need them on a different component rather than array of objects
   as they would be 1 step behind using t('') inside array */
  const DefaultItemsNav = () => (
    <>
      <NavigationItem
        data-testid={'project-navigation-button-about-project'}
        key={'About the project'}
        href={'/'}
        onClick={() => trackClick('about-project')}
        className={router?.pathname === '/' && 'selected'}
      >
        {navbarV2 ? iconMap['Book'] : iconMap['hub']}
        <span>{t('About the project')}</span>
      </NavigationItem>

      <NavigationItem
        data-testid={'project-navigation-button-timeline'}
        key={'About the project'}
        href={'/timeline'}
        onClick={() => trackClick('timeline')}
        className={router?.pathname === '/timeline' && 'selected'}
      >
        {navbarV2 ? iconMap['CalendarTime'] : iconMap['milestones']}
        <span>{t('Timeline')}</span>
      </NavigationItem>

      <NavigationItem
        data-testid={'project-navigation-button-news'}
        key={'Latest news'}
        href={'/news'}
        onClick={() => trackClick('news')}
        className={router?.pathname === '/news' && 'selected'}
      >
        {navbarV2 ? iconMap['Send'] : iconMap['news']}
        <span>{t('Latest news')}</span>
      </NavigationItem>

      <NavigationItem
        data-testid={'project-navigation-button-proposals'}
        key={'All tiles'}
        href={'/proposals'}
        onClick={() => trackClick('proposals')}
        className={router?.pathname === '/proposals' && 'selected'}
      >
        {navbarV2 ? iconMap['Apps'] : iconMap['proposals']}
        <span>{t('All tiles')}</span>
      </NavigationItem>

      <NavigationItem
        data-testid={'project-navigation-button-team'}
        key={'The team'}
        href={'/project-team'}
        onClick={() => trackClick('team')}
        className={router?.pathname === '/team' && 'selected'}
      >
        {navbarV2 ? iconMap['UsersGroup'] : iconMap['team']}
        <span>{t('The team')}</span>
      </NavigationItem>
    </>
  );

  const CustomItemsNav = () => (
    <>
      {navItems
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map(({ name, link, label, icon, customSvg }: MenuItem) => {
          const isSelected = router?.pathname === link;
          return (
            <NavigationItem
              key={label}
              href={link}
              data-testid={`project-navigation-button-${name}`}
              onClick={() => trackClick(name)}
              className={isSelected && 'selected'}
            >
              {customSvg ? (
                <SvgRenderer
                  customSvg={customSvg}
                  color={
                    isSelected
                      ? theme.colorMappings.hyperlink
                      : 'rgba(0,0,0,0.54)'
                  }
                />
              ) : (
                iconMap[icon] || (
                  <IconQuestionMark width={20} height={20} stroke={1.5} />
                )
              )}
              <span>{label}</span>
            </NavigationItem>
          );
        })}
    </>
  );

  return (
    <nav>
      <BottomNavigation
        data-onboarding="navigation-bar"
        data-testid="ProjectNavigation"
        showLabels={showLabels}
      >
        {navItems?.length ? <CustomItemsNav /> : <DefaultItemsNav />}
      </BottomNavigation>
    </nav>
  );
};

export { ProjectNavigation };
