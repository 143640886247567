import { gql } from '@apollo/client';
import { captureException } from '@sentry/node';
import getClient from 'Shared/utils/apollo-client';

const GET_PROJECT_SETTINGS = gql`
  query GetProjectSettings($getProjectSettingsInput: GetProjectSettingsInput) {
    getProjectSettings(GetProjectSettingsInput: $getProjectSettingsInput) {
      _id
      projectId
      defaultLocale
      locales
      name
      subdomain
      openGraph
    }
  }
`;

export const fetchProjectSettingsGql = async ({ projectId, apiToken }) => {
  try {
    const client = getClient(apiToken);
    const { data } = await client.query({
      query: GET_PROJECT_SETTINGS,
      variables: {
        getProjectSettingsInput: {
          projectId,
        },
      },
      context: {
        fetchOptions: {
          method: 'GET',
        },
      },
    });
    return data.getProjectSettings;
  } catch (error) {
    console.error(error);
    captureException(
      `error in fetchProjectSettings @ fetchProjectSettings.ts : ${error}`
    );
    return null;
  }
};
