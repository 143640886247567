import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { ProjectSettings } from 'Shared/types/settings';
import { fetchProjectSettingsGql } from './fetchProjectSettings.gql';

const fetchProjectSettingsOld = async (
  projectId: string
): Promise<ProjectSettings> => {
  const res = await fetch(`/api/project/${projectId}/settings`);
  const jsonSettings = (await res.json()) || null;
  return jsonSettings?.data?.settings;
};

export const fetchProjectSettings = async ({
  projectId,
  projectRequestsOnGql,
  apiToken,
}: {
  projectId: string;
  projectRequestsOnGql: boolean;
  apiToken: string;
}): Promise<ProjectSettings> => {
  try {
    if (projectRequestsOnGql) {
      return await fetchProjectSettingsGql({ projectId, apiToken });
    }
    /* This needs to be deprecated once confirmed the feature above works as intended */
    return fetchProjectSettingsOld(projectId);
  } catch (error) {
    captureException(
      `error in fetchProjectSettings @ fetchProjectSettings.ts : ${error}`
    );
    return null;
  }
};
