import { gql } from '@apollo/client';
import { User } from 'Shared/types';
import getClient from 'Shared/utils/apollo-client';

export const GET_USER_BY_EMAIL = gql`
  query Query($email: String!) {
    getUserByEmail(email: $email) {
      _id
      email
      status
      projects
      firstName
      lastName
      firstSeen
      language
    }
  }
`;

export const getUserByEmail = async (
  apiToken: string,
  email: string
): Promise<User> => {
  const client = getClient(apiToken);

  const { data } = await client.mutate({
    mutation: GET_USER_BY_EMAIL,
    variables: {
      email,
    },
  });
  return data.getUserByEmail;
};
