import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { ProjectSettings } from 'Shared/types/settings';
import { updateProjectSettingsGql } from './updateProjectSettings.gql';

const updateProjectSettingsOld = async ({
  projectId,
  data,
}: {
  projectId: string;
  data: Record<string, unknown>;
}): Promise<ProjectSettings> => {
  const res = await fetch(`/api/project/${projectId}/settings`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    return null;
  }

  const result = await res.json();

  return result?.data?.settings;
};

export const updateProjectSettings = async ({
  projectId,
  data,
  projectRequestsOnGql,
  apiToken,
}: {
  projectId: string;
  data: Record<string, unknown>;
  projectRequestsOnGql: boolean;
  apiToken: string;
}): Promise<ProjectSettings> => {
  try {
    if (!projectId) {
      throw new Error('Invalid arguments');
    }
    if (projectRequestsOnGql) {
      return await updateProjectSettingsGql({ projectId, data, apiToken });
    }
    /* This needs to be deprecated once confirmed the feature above works as intended */
    return updateProjectSettingsOld({ projectId, data });
  } catch (error) {
    captureException(
      `error in updateProjectSettings @ updateProjectSettings.ts : ${error}`
    );
    return null;
  }
};
