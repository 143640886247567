import * as React from 'react';
import { useRouter } from 'next/router';
import parseHtml from 'html-react-parser';
import { isLocal } from 'Shared/utils';
import { User } from 'Shared/types/user';
import { ProjectProps } from 'Shared/types';
import { userHasPermission } from 'Client/utils/user';
import { Permissions } from 'Client/constants/permissions';

interface AccessibilityWidgetProps {
  deviceType: string;
  isRtl: boolean;
  user: User;
  project: ProjectProps;
}

export const AccessibilityWidget = ({
  deviceType,
  isRtl,
  user,
  project,
}: AccessibilityWidgetProps): JSX.Element => {
  const { pathname } = useRouter();
  /**
   * Initial conditions - only show:
   * if live
   * if lang is LTR
   * if device desktop or device is mobile and feature flag is enabled
   */
  const isDeviceAllowed = (() => {
    if (deviceType === 'desktop') return true;
    if (!project || !project.features) return false;
    if (deviceType === 'mobile' && project.features.accessibilityOnMobile) {
      return true;
    }
    return false;
  })();
  if (isLocal) return null;
  if (isRtl) return null;
  if (!isDeviceAllowed) return null;

  const isEditMapUser = userHasPermission(user, Permissions.MAP_EDIT_MODE);
  const editModeRegex = new RegExp('/edit');

  /**
   * Role and location specifics - do not show:
   * if we are in edit mode
   * if we are on map page and user has edit map permission
   * if we are on project centre
   * if we are on new dashboard
   */
  if (
    pathname.includes('/v5/dashboard') ||
    pathname.includes('/project-centre') ||
    pathname.includes('/chart') ||
    editModeRegex.test(pathname) ||
    (isEditMapUser && pathname.startsWith('/map'))
  ) {
    return null;
  }

  // Equalweb code:
  return (
    <>
      {parseHtml(`
        <script>
        window.interdeal = {
          "sitekey": "0fafd55273fcd2594cc7711b2182badf",
          "Position": "left",
          "domains": {
              "js": "https://cdn.equalweb.com/",
              "acc": "https://access.equalweb.com/"
          },
          "Menulang": "en-GB",
          "btnStyle": {
              "vPosition": [
                  "80%",
                  "20%"
              ],
              "scale": [
                  "0.5",
                  "0.5"
              ],
              "color": {
                  "main": "#792f88",
                  "second": "#ffffff"
              },
              "icon": {
                  "outline": false,
                  "type": 11,
                  "shape": "semicircle"
              }
          }
        };
        (function(doc, head, body){
          var coreCall             = doc.createElement('script');
          coreCall.src             = interdeal.domains.js + 'core/5.0.6/accessibility.js';
          coreCall.defer           = true;
          coreCall.integrity       = 'sha512-rte1aosyfa9h+YnUZ5NHJoYMmVaAMjoGkGWQgMv5Wy/YBhub9fJsbKgUCVo8RUUL2Mxk2AaezXWDR+LzJUU7Mg==';
          coreCall.crossOrigin     = 'anonymous';
          coreCall.setAttribute('data-cfasync', true );
          body? body.appendChild(coreCall) : head.appendChild(coreCall);
        })(document, document.head, document.body);
        </script>
        `)}
    </>
  );
};
