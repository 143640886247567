import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'Client/redux-store';
import { setLocale } from 'Client/utils/reduxReducers/locale/localeReducer';
import { LanguageToggle, LanguageDropdown } from 'Atoms';
import { useProject, useUser } from 'Client/utils/hooks';
import { checkOnUserLanguage } from 'Client/services/user';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { LanguageSelectorProps } from './types';

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  isEditMode,
  editModeCallback,
}: LanguageSelectorProps) => {
  const { user } = useUser();
  const { locale, asPath, replace } = useRouter();
  const { i18n } = useTranslation();
  const { projectLocales } = useSelector((state: RootState) => state.locale);
  const dispatch = useDispatch();
  const { apiToken } = useUtils();
  const project = useProject();
  React.useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale);
      if (asPath !== '/login') {
        // needed for i18n to track the changes
        applyLanguage(locale);
      }
    }
  }, [locale]);

  const applyLanguage = async (lang: string) => {
    if (!isEditMode) {
      await checkOnUserLanguage({
        user,
        i18nLang: lang,
        userEndpointsOnGql: project.features.userEndpointsOnGql,
        apiToken,
      });
    }
    dispatch(setLocale(locale));
    replace(asPath, asPath, {
      scroll: false,
      locale: lang,
    });
  };

  if (!projectLocales || (projectLocales && projectLocales.length < 2))
    return null;

  if (projectLocales && projectLocales.length === 2)
    return (
      <LanguageToggle
        isEditMode={isEditMode}
        projectLocales={projectLocales}
        applyLanguage={applyLanguage}
        editModeCallback={editModeCallback}
      />
    );

  return (
    <LanguageDropdown
      isEditMode={isEditMode}
      projectLocales={projectLocales}
      applyLanguage={applyLanguage}
      editModeCallback={editModeCallback}
    />
  );
};
