import { User } from 'Shared/types/user';
import { fetchUserByEmail } from '.';

export const getCurrentUserId = async (
  user: User | undefined,
  storedUserId: string | undefined, // eg localStorage (currently) or cookies (previously)
  signupEmail: string | undefined,
  userEndpointsOnGql: boolean,
  apiKey: string
): Promise<string | null> => {
  if (user) {
    return user.id;
  } else if (storedUserId) {
    return storedUserId;
  } else if (signupEmail) {
    return (await fetchUserByEmail(signupEmail, userEndpointsOnGql, apiKey))
      ?._id;
  } else {
    return null;
  }
};
