import { ReactNode } from 'react';
import { FileUploadAcceptTypes } from 'Client/components/molecules/FileUpload/types';
import { OptionItem } from 'Client/types';
import { OpenGraphProps, ProjectProps } from 'Shared/types';
import { Page, PageFeatures } from 'Shared/types/page';
import { PlanningAppPageAndContent } from 'Shared/types/planningapp';
import { MapPageContent } from 'Shared/types/proposalPage';
import { SearchAddressTypes } from 'Shared/types/getAddress';
import {
  ImageCommentQuestionAnswer,
  LabelValueOptions,
  MapQuestionAnswer,
  MatrixQuestionAnswer,
  QuestionTypes,
} from 'Shared/types/question';
import { PollImage } from 'Client/components/molecules/ImagePoll/types';
import { GLWorkspace } from 'Shared/types/proposals';
import { DynamicProposalsContent } from '../shared/tiles/types';
import { ImageMapProContent } from '../hub/types';
import { Proposal } from '../hub/components/DynamicProposals/types';

// TODO: move all question-related types to shared/types/question.ts

export interface IImagePollAnswer {
  label: string;
  value: string;
  url: string;
  title: string;
}

export interface PriorityListItem {
  label: string;
  value: string;
  icon?: string;
}

export interface RatingOption {
  value: number | string; // number if feeling (0-25-50-75-100), string if non-feeling
  label: string;
  colourHex?: string;
}

export interface YesNoOption {
  value: boolean;
  label: string;
}
export interface BudgetOption {
  label: string;
  value: string;
  min?: number;
  max?: number;
  defaultLocation?: number;
  minMessage?: string;
  maxMessage?: string;
  reduceMessage?: string;
}

export type MultiselectOption = {
  label: string;
  value: string;
  checked: boolean;
  icon?: ReactNode;
};

export interface MatrixContent {
  columns: Array<{ label: string; value: number }>;
  topics: Array<{ label: string; value: string }>;
}

export interface Question {
  id: string;
  label: string;
  type: QuestionTypes;
  name?: string;
  placeholder?: string;
  values?: Array<string | PollImage | PriorityListItem | LabelValueOptions>;
  selected?: Array<string>; // TODO fix/deprecate this
  options?: Array<
    | string // TODO: deprecate this!
    | number
    | RatingOption
    | OptionItem
    | YesNoOption
    | LabelValueOptions
    | BudgetOption
  >;
  leftLabel?: string;
  rightLabel?: string;
  selectMultiple?: boolean;
  showImageLabels?: boolean;
  showOptionLabels?: boolean;
  showLeftRightLabels?: boolean;
  horizontalOptions?: boolean;
  addOtherText?: boolean;
  secondaryText?: string;
  showSecondaryText?: boolean;
  order?: number;
  isCreatable?: boolean;
  isClearable?: boolean;
  voiceCapture?: boolean;
  subtitle?: string;
  allowedFileTypes?: FileUploadAcceptTypes;
  maxNumberOfFiles?: number;
  category?: string;
  displayBudget?: string;
  totalLabel?: string;
  maxBudget?: number;
  currency?: string;
  unit?: string;
  unspentLabel?: string;
  matrixOptions?: MatrixContent;
  showColours?: boolean; // rating-number & smilie
  infoTip?: string;
  searchFor?: SearchAddressTypes[];
  backgroundColour?: string;
}

export interface Image {
  type?: 'banner' | 'container';
  title?: string;
  subtitle?: string;
  src: string;
  alt: string;
}

export interface ImageComparison {
  showCaption: boolean;
  startPosition: OptionItem;
  beforeLeft: {
    uri: string;
    caption: string;
    description: string;
  };
  afterRight: {
    uri: string;
    caption: string;
    description: string;
  };
}

export enum ImageAndGridLayoutTypes {
  FULL_WIDTH = 'fullWidth',
  CAROUSEL = 'carousel',
  IMAGE_AND_TEXT = 'imageAndText',
  GRID = 'grid',
  DEFAULT = 'default',
}

export enum ImageAlignmentTypes {
  TOP = 'text-on-top',
  LEFT = 'text-on-left',
  RIGHT = 'text-on-right',
  BOTTOM = 'text-on-bottom',
}

export enum ImageOrientationTypes {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
}

export interface AlignmentOptionsProps {
  label: string;
  icon: ReactNode;
  value: ImageAlignmentTypes;
}

export interface ImageAndGridFileProps {
  description: string;
  alt: string;
  url: string;
  orientation: ImageOrientationTypes;
}

export type ImageAndGridContent = {
  layout: ImageAndGridLayoutTypes;
  files: Array<ImageAndGridFileProps>;
  alignment?: ImageAlignmentTypes;
};

export type AccordionItem = {
  title: string;
  description: string;
};

export type AccordionContent = {
  mainTitle: string;
  accordionItems: Array<AccordionItem>;
};

export type NavMap = {
  title: string;
  slug: string;
};

export interface ProposalSection<Q> {
  _id?: string; // identifier to content blocks
  title?: string;
  subtitle?: string;
  description?: string;
  imageComparisonSlider?: ImageComparison;
  imageAndGrid?: ImageAndGridContent;
  // question is a string when fetched from the db, or Question when passed down to the component.
  // It can also be absent (not existing in the section as fetched from the db)
  // or undefined (in the Section component itself)
  question?: Q | undefined | null;
  // a section can have an array of sections...
  sections?: Array<ProposalSection<Q>> | null;
  image?: Image;
  order: number;
  files?: Array<MediaUploadFile>;
  allowedFileTypes?: string;
  maxNumberOfFiles?: number;
  pagePreview?: DynamicProposalsContent;
  accordionContent?: AccordionContent;
  twoColumns?: TwoColumns;
  navMap?: NavMap;
  /**
   * Used in skip logic proposals to prevent Commonplace footer navigation from showing.
   */
  hideProposalFooter?: boolean;
  backgroundColour?: string;
  imageMapPro?: ImageMapProContent;
  branchingLogic?: IBranchingLogic;
}

export interface IBranchingLogic {
  rules: IRules[];
  redirect: {
    default: string;
  };
}

interface IRules {
  rule: Array<
    | {
        [key: string]: {
          [key: string]: [value: string | number];
        };
      }
    | string
  >;
  redirect: {
    goTo: string;
  };
}

export enum ProposalStage {
  ACTIVE = 'active',
  DRAFT = 'draft',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
  CLOSED = 'closed',
}

export const BlockContentStages = ['completed', 'closed', 'archived'];

type ProposalPageContentBase<SectionType> = {
  card: CardContent;
  geolytixWorkspace?: GLWorkspace;
  steps: ProposalSection<SectionType>[];
  featureFlags?: PageFeatures;
};

/**
 * The content props of a proposal page.
 * Can be found within a `content` or a `pageContent` prop, or already spread in a proposal page.
 */
export type ProposalPageContent = ProposalPageContentBase<string>;

export interface CardContent {
  title: string;
  description?: string;
  image: Image;
  ctaText?: string;
  contributionsNumber?: number; // not included in the db, but fetched in the FE and included in the UI
  order?: number;
}

export type ProposalQuestionsContent = ProposalPageContentBase<Question>;

export type ProposalPageWithQuestionContent = Page<ProposalQuestionsContent>;

export interface ProposalPageProps {
  proposalContent: ProposalQuestionsContent;
  proposalId: string;
  proposalTitle: string;
  proposalSlug: string;
  stage: ProposalStage | null;
  steps: Array<ProposalSection<Question>>;
  questions?: Array<Question>;
  project?: ProjectProps;
  otherProposals?: Proposal[];
  planningApps?: Array<PlanningAppPageAndContent>;
  otherPlanningApps?: Array<PlanningAppPageAndContent>;
  contributionsNumber?: number;
  showMinutesLeft?: boolean;
  proposalHasQuestions: boolean;
  prefillAnswersFromContribution: (cid: string) => void;
  openGraphInfo: OpenGraphProps;
  numOfProposalSteps: number;
  printPage?: boolean;
}

export interface ConfirmationPageProps {
  proposalId: string;
  proposalTitle: string;
  proposalSlug: string;
  proposalStage: string;
  questions?: Array<Question>;
  otherProposals?: Proposal[];
  numOfProposalSteps: number;
  proposalHasQuestions: boolean;
  prefillAnswersFromContribution: (cid: string) => void;
  openGraphInfo: OpenGraphProps;
}

export interface BudgetAnswerDataProps {
  label: string;
  value: string;
  allocated: number;
}

export type QuestionValue =
  | string
  | number
  | Array<string>
  | OptionItem
  | ImageCommentQuestionAnswer
  | MatrixQuestionAnswer
  | MediaUploadFile[]
  | RespondentUploadAnswer
  | BudgetAnswerDataProps[]
  | MapQuestionAnswer;

export type RespondentUploadAnswer = {
  files: RespondentUploadFile[];
  publicPdfRequest: boolean;
};

export type RespondentUploadFile = MediaUploadFile & {
  type: string;
  moderationStatus: ModerationStatusTypes;
};

export type MediaUpload = {
  title: string;
  files: Array<MediaUploadFile>;
};

export type TwoColumns = {
  left: {
    content: string;
  };
  right: {
    content: string;
  };
};

export type MediaUploadFile = {
  name: string;
  url: string;
  type?: string;
  moderationStatus?: ModerationStatusTypes;
  thumbnail?: string;
  previewImage?: string;
};

export enum ModerationStatusTypes {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export interface Answers {
  [questionId: string]: QuestionValue;
}

export interface SectionProps {
  title?: string;
  description?: string;
  imageComparisonSlider?: ImageComparison;
  imageAndGrid?: ImageAndGridContent;
  question?: Question | null;
  sections?: Array<ProposalSection<Question>> | null;
  image?: Image;
  // both value and onChange can be null when Section is used in the start page of a proposal (step0)
  value?: QuestionValue | undefined; // value is undefined when the section doesn't have a question
  voiceValue?: string | undefined;
  onChange?: (qId: string, val: QuestionValue) => void;
  onBlur?: (qId: string, val: QuestionValue) => void;
  showProgressBar: boolean;
  showMinutesLeft?: boolean;
  hideQuestion?: boolean;
  files?: Array<MediaUploadFile>;
  pagePreview?: DynamicProposalsContent;
  accordionContent?: AccordionContent;
  twoColumns?: TwoColumns;
  navMap?: NavMap;
  backgroundColour?: string;
  imageMapPro?: ImageMapProContent;
  sectionId?: string;
}

export interface CommentAnswer {
  id?: string;
  label?: string;
  type?: string; // eg text, smilie
  name?: string; // eg feeling
  value?: QuestionValue;
  selected?: QuestionValue;
  answerLabel?: string;
}

export interface EditFormProps {
  exitEditComment: (noAnswers: boolean) => void;
  answers?: Array<Question>;
  proposalSlug: string;
  contributionId: string;
}

export interface OtherProposalsReelProps {
  proposals?: Proposal[];
  planningApps?: Array<PlanningAppPageAndContent>;
  project?: ProjectProps;
  showUnderline?: boolean;
  isMultiStep?: boolean;
}

export interface StartPageProps {
  proposalTitle: string;
  proposalSlug: string;
  proposalStage: ProposalStage | null;
  steps: Array<ProposalSection<Question>>;
  proposalHasQuestions: null;
  projectName: string;
  projectStage: string;
}

export interface ProposalSectionRenderProps extends SectionProps {
  steps: Array<ProposalSection<Question>>;
  handleChange?: (questionId: string, answer: QuestionValue) => void;
  onClick?: () => void;
  draggingOverElemId?: string | undefined;
}

export interface EmptyAnswersPageProps {
  proposalTitle: string;
  proposalSlug: string;
  otherProposals?: Proposal[];
  consentedToNews?: boolean;
}

export type ProposalHubSection = Page<ProposalPageContent | MapPageContent>;
