import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { ProjectGaudi } from 'Shared/types';
import { MixpanelEventTypes } from 'Client/utils/hooks';
import { mixpanel } from 'Client/utils/hooks/useAnalytics/mixpanel';
import { ContributionGaudi } from 'Shared/types/contribution';
import { getUserById } from '../user/findUserById.gql';

export const createContributionGaudi = async (
  contrDataForGaudi: Partial<ContributionGaudi>,
  apiToken: string
): Promise<{ data: ContributionGaudi; message: string; success: boolean }> => {
  try {
    const response = await fetch('/api/contributions?originDb=gaudi', {
      method: 'POST',
      body: JSON.stringify(contrDataForGaudi),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const project = (await fetch(
      `/api/projects/${contrDataForGaudi.projectId}`
    ).then((res) => res)) as ProjectGaudi;
    const jsonContribution = await response.json();
    if (project?.features?.trackContributionFlow) {
      const user = await getUserById(jsonContribution.userId, apiToken);
      mixpanel(user, project, 'en-GB').trackEvent(
        MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW,
        {
          fileName:
            'src/client/services/contributions/createContributionGaudi.ts',
          functionName: 'createContributionGaudi',
          database: 'gaudi',
          fieldToBeUpdated: Object.keys(jsonContribution),
          gaudiUpdate: jsonContribution as unknown as Record<string, unknown>,
          acornUpdate: null,
          userId: jsonContribution.userId,
          demographicsId: jsonContribution?.demographicsId,
          contributionId: jsonContribution._id,
        }
      );
    }
    return jsonContribution;
  } catch (err) {
    captureException(err);
    return null;
  }
};
