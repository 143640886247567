import fetch from 'isomorphic-unfetch';
import getConfig from 'next/config';
import { captureException } from '@sentry/node';
import axios from 'axios';
import { Geometry } from 'ol/geom';
import GeoJSON, { GeoJSONPoint } from 'ol/format/GeoJSON';
import { ApolloClient } from '@apollo/client';
import {
  ContributionMapEntry,
  EditingMapFeature,
  GLLocale,
  MapProjection,
  Xyz,
  XyzV4,
} from 'Shared/types/map';
import { BATCH_UPDATE_FEATURES } from '../map/batchUpdateFeatures.gql';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

const geolytixApiUrl =
  publicRuntimeConfig?.geolytix?.urlV4 || serverRuntimeConfig?.geolytix?.urlV4;

/**
   * Removed as was only storing data for 1 ms
   * Kept the code as we might bring it back later
    const getCacheStore = () => {
      // in-memory cache store for browsers that disables cookies
      if (!navigator.cookieEnabled) return undefined;

      // uses one of the following drivers in order, checking their availability
      return createInstance({
        driver: [INDEXEDDB, WEBSQL, LOCALSTORAGE],
      });
    };
    const cache = setupCache({
      maxAge: 1,
      exclude: {
        query: false,
        methods: ['post', 'patch', 'put', 'delete', 'get'],
        paths: [/auth/],
      },

      store: getCacheStore(),
    });
*/

const cachedApi = () => {
  return axios.create({
    // adapter: cache.adapter,
  });
};

export function getLocale(
  locales: Array<{ key: string; name: string }>,
  xyz: Xyz // Not yet working with v4
): GLLocale {
  if (!locales.length) {
    console.log('No accessible locales');
    return;
  }

  const locale =
    xyz.hooks && xyz.hooks.current.locale
      ? {
          key: xyz.hooks.current.locale,
          name: locales.find((l) => l.key === xyz.hooks.current.locale).name,
        }
      : locales[0];

  return xyz.workspace.get.locale({
    locale: locale.key as string,
  });
}

// export async function createMap(locale: GLLocale, xyz: Xyz, target: Element) {
//   xyz.locale = locale;

//   xyz.mapview.create({
//     target,
//     scrollWheelZoom: true,
//   });

//   await xyz.layers.load();

//   return xyz;
// }

const customDataFetcher = ({
  method,
  content_type,
  url,
  body,
  project,
  pageId,
  lang = 'en-GB',
}: {
  method?: 'post' | 'get' | 'put' | 'delete';
  content_type?: string;
  url: string;
  body?: any;
  project: string;
  pageId: string;
  lang: string;
}): Promise<any> => {
  if (!content_type) content_type = 'application/json';

  if (!method) method = 'get';

  url = encodeURI(url);

  const separator = url.includes('?') ? '&' : '?';

  let params = '';

  if (!url.includes('lang=')) {
    params += `&lang=${lang}`;
  }

  if (!url.includes('project=')) {
    params += `&project=${project}`;
  }

  if (!url.includes('pageId=')) {
    params += `&pageId=${pageId}`;
  }

  url = url + `${separator}${params}`;

  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    return (cachedApi() as any)
      [method as any](url, body, {
        headers: { 'Content-Type': content_type },
      })
      .then((res) => resolve(res.data))
      .catch(reject);
  });
};

type XyzParams = {
  mapp;
  target: Element;
  project: string;
  pageId: string;
  hooks: boolean;
  lang: string;
};

export const initXyz = async ({
  mapp,
  target,
  project,
  pageId,
  hooks,
  lang = 'en-GB',
}: XyzParams): Promise<XyzV4> => {
  // Maybe put the abstraction layer here?
  mapp.utils.xhr = (url: string) =>
    customDataFetcher({
      url,
      project,
      pageId,
      lang,
    });

  const locales = await mapp.utils.xhr(
    `${geolytixApiUrl}/api/workspace/locales`
  );

  const locale = await mapp.utils.xhr(
    `${geolytixApiUrl}/api/workspace/locale?locale=${
      document.head.dataset.locale ||
      mapp.hooks.current.locale ||
      locales?.[0]?.key ||
      'en-GB'
    }`
  );

  const layers = await mapp.utils.promiseAll(
    locale.layers.map((layer) =>
      mapp.utils.xhr(
        `${geolytixApiUrl}/api/workspace/layer?` +
          `locale=${locale.key}&layer=${layer}`
      )
    )
  );

  mapp.mapview = mapp.Mapview({
    host: geolytixApiUrl,
    scrollWheelZoom: true,
    scalebar: 'metric',
    target,
    hooks,
    locale,
  });

  await mapp.mapview.addLayer(layers);

  return mapp;
};

export const getNnearest = (
  location,
  xyz: Xyz,
  project,
  pageId: string,
  n,
  filter?: string,
  layer = 'Contributions',
  table = 'contributions'
) => {
  const locationGeometry = location.geometry || location.getGeometry();

  const geoJson = new GeoJSON().writeGeometryObject(locationGeometry, {
    dataProjection: MapProjection.WORLD_GEODETIC_GPS,
    featureProjection: MapProjection.WEB_MERCATOR,
  }) as GeoJSONPoint;

  return cachedApi()
    .get(
      `${geolytixApiUrl}/api/query/get_nnearest?${xyz.utils.paramString({
        locale: 'UK',
        layer,
        geom: 'geom',
        qID: 'id',
        n,
        cluster_label: 'id',
        table,
        coords: geoJson.coordinates,
        x: geoJson.coordinates[0],
        y: geoJson.coordinates[1],
        project,
        pageId,
        lang: 'en-GB',
        filter: filter ? Buffer.from(filter).toString('base64') : null,
      })}`
    )
    .then((res) => {
      return res.data;
    });
};

export const getPointData = async (
  xyz,
  id,
  layer,
  pageId,
  project
): Promise<{
  geometry: Geometry;
  properties: Partial<ContributionMapEntry>;
}> => {
  return cachedApi()
    .get(
      `${geolytixApiUrl}/api/location/get?locale=${xyz.locale.key}&layer=Contributions&table=${layer.table}&id=${id}&pageId=${pageId}&project=${project}&lang=en-GB`
    )
    .then((res) => res.data)
    .catch((error) => {
      captureException(error);
    });
};

export const getContributionPoint = async (
  location: any,
  xyz: Xyz,
  project: string,
  pageId: string,
  n: number,
  id?: string
): Promise<{
  geometry?: Geometry;
  properties: Partial<ContributionMapEntry>;
}> => {
  try {
    /* Current use cases for this function:
     * 1: point was not unclustered - [action: click] (has no id) = query feature from it's position
     * 2: point was unclustered - [action: click] (has no id) = query feature from it's position
     * 3: point was unclustered & 'spidered' - [action: click] (has id) = query feature from id via feature from event
     * 4: point was unclustered - [action: hover] (has id) = query feature from id via state.highlightedContribution
     */

    const layer = xyz.layers.list.Contributions;

    const validPageFilter = [
      {
        is_deleted: { eq: false },
        draft: { eq: false },
        page_id: { eq: pageId },
        project: { eq: project },
      },
    ];

    const filter =
      xyz?.layers?.list.Contributions?.filter?.current ||
      JSON.stringify(validPageFilter);

    const point = id
      ? await getPointData(xyz, id, layer, pageId, project)
      : {
          properties: (await getNnearest(
            location,
            xyz,
            project,
            pageId,
            n,
            filter
          )) as Partial<ContributionMapEntry>,
        };

    if (typeof point !== 'object') {
      const res: Partial<ContributionMapEntry> = await getNnearest(
        location,
        xyz,
        project,
        pageId,
        n,
        filter
      );

      return { properties: res };
    }

    return point;
  } catch (e) {
    captureException(e);
  }
};

export const getPoint = async (
  location,
  xyz: Xyz, // Not yet working with v4
  pageId: string,
  project: string
) => {
  try {
    const layer = xyz.mapview.layers[location.layer];
    return await cachedApi()
      .get(
        `${geolytixApiUrl}/api/location/get?locale=${xyz.locale.key}&layer=${location.layer}&table=${layer.table}&id=${location.id}&pageId=${pageId}&project=${project}&lang=en-GB`
      )
      .then((res) => res.data);
  } catch (err) {
    captureException(`Error in client/services/geolytics:getPoint()`, err);
    return undefined;
  }
};

export const get3dPoint = async (
  location,
  xyz: Xyz, // Not yet working with v4
  pageId: string,
  project: string
) => {
  const layer = xyz.mapview.layers['3d View'];
  const { id } = location.getProperties();

  return await cachedApi()
    .get(
      `${geolytixApiUrl}/api/location/get?locale=${xyz.locale.key}&layer=${layer.key}&table=${layer.table}&id=${id}&pageId=${pageId}&project=${project}&lang=en-GB`
    )
    .then((res) => res.data);
};

/**
 * WARNING: Currently not working
 */
export const savePolygon = async ({
  geometry,
  properties,
  xyz,
  layer,
  pageId,
  project,
  language,
}): Promise<any> => {
  return fetch(
    `${geolytixApiUrl}/api/location/new?locale=UK&layer=${layer}&table=${xyz.layers.list[layer].table}&pageId=${pageId}&project=${project}&lang=${language}`,
    {
      method: 'POST',
      body: JSON.stringify({
        geometry,
        properties: {
          metadata: properties.metaData,
        },
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }
  ).then(async (res) => res.json());
};

export const get3dImage = (id: string): any => {
  return cachedApi()
    .get(
      `${geolytixApiUrl}/api/location/get?locale=UK&layer=3d View&table=image_3d&id=${id}`
    )
    .then((res) => res.data);
};

export const getLayerGeojson = ({
  xyz,
  project,
  pageId,
  filter,
  layer,
  table,
  fields = [],
}) => {
  return cachedApi()
    .get(
      `${geolytixApiUrl}/api/layer/geojson?${xyz.utils.paramString({
        locale: 'UK',
        layer: layer,
        table: table,
        project,
        pageId,
        lang: 'en-GB',
        filter,
        fields,
      })}`
    )
    .then((res) => res.data);
};

export const updateFeature = ({ id, layer, table, xyz, body }) => {
  return cachedApi()
    .post(
      `${geolytixApiUrl}/api/location/update?${xyz.utils.paramString({
        locale: 'UK',
        layer,
        table,
        id,
      })}`,
      body
    )
    .then((res) => res.data);
};

export const updateFeaturesMetadata = async (
  features: EditingMapFeature[],
  client: ApolloClient<object>
) => {
  try {
    return await client.mutate({
      mutation: BATCH_UPDATE_FEATURES,
      variables: {
        features: features,
      },
    });
  } catch (e) {
    console.error(e);
    throw Error(e.message);
  }
};
