import { DynamicProposalsRules } from 'Shared/types/proposalPage';
import { getExternalProposalsGql } from './getExternalProposals.gql';

const getExternalProposalsOldWay = async ({
  rules,
  listOrder,
  language,
  editModeOptions,
  unselectedProposals,
  ignoreHidden,
}: Omit<GetExternalProposalsProps, 'externalProposalsRequestOnGql'>) => {
  const data = await fetch('/api/external/proposals', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      rules,
      order: listOrder,
      lang: language,
      unselectedProposals,
      editModeOptions,
      ignoreHidden,
    }),
  });
  return data.json();
};

interface GetExternalProposalsProps {
  rules: DynamicProposalsRules[];
  listOrder: string;
  language: string;
  unselectedProposals: string[];
  editModeOptions: boolean;
  ignoreHidden: boolean;
  externalProposalsRequestOnGql: boolean;
  projectId: string;
  apiToken: string;
}

export const getExternalProposals = async ({
  rules,
  listOrder,
  language,
  unselectedProposals,
  editModeOptions,
  ignoreHidden,
  externalProposalsRequestOnGql,
  projectId,
  apiToken,
}: GetExternalProposalsProps) => {
  const props = {
    rules,
    listOrder,
    language,
    unselectedProposals,
    editModeOptions,
    ignoreHidden,
    projectId,
    apiToken,
  };
  if (externalProposalsRequestOnGql) {
    return await getExternalProposalsGql(props);
  }
  return await getExternalProposalsOldWay(props);
};
