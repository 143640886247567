import { lighten, opacify } from 'polished/lib';
import { Color } from '@material-ui/core';
import { generateFontSizes } from './utils/generateFontSizes';

const colors: {
  [colorName: string]: Partial<Color>;
} = {
  green: {
    50: '#F0FAF6', // hover menu items
    100: '#dbf8ec', // selected
    200: '#e9f9ee',
    300: '#89C92D', // sentiments_mostly_positive
    400: '#00a863',
    500: '#00AC5D', // brand_1
    600: '#09834b', // (button_pressed_state)
    700: '#00baae', // milestone green
    800: '#cedfd7',
    900: '#175139',
    A100: 'rgba(14, 14, 14, 0.5);', // corresponds to #075330 with opacity 0.5, but a bit darker
    A200: '#1e724b',
    A400: '#6BBF59', // status confirmed
    A700: '#00a85a', // accessible brand green
  },
  yellow: {
    100: '#fff7e1',
    500: '#FEDC08', // accent_4_yellow
    700: '#FFB502',
  },
  orange: {
    100: '#FFF3CD',
    300: '#FF7700',
    500: '#FEB000', // sentiments_neutral,
    600: '#FF8B75',
    700: '#FF7F00', // sentiments_mostly_negative
    800: '#675319',
    900: '#836D2D',
    A100: '#E8871E', // status pending
  },
  white: {
    200: '#CCCCCC',
    300: '#e1e3e6',
    400: '#f8f8f8',
    500: '#FFFFFF',
    600: '#f5f5f5',
    700: '#FAFBFF', // Kind of bluey white
    800: '#dae1de',
  },
  black: {
    50: '#333333',
    100: '#EFEFEF',
    200: '#47474f',
    300: '#3F3F3F',
    400: '#2D2926',
    500: '#000000',
    600: '#212d5a', // Kind of bluey black...
    700: '#2a374e',
  },
  grey: {
    50: '#F9F9F9', // Grey-2
    100: '#F2F2F2',
    200: '#DDDDDD',
    300: '#D9D9D9', // Grey-15
    400: '#CDCDCD',
    500: '#949494', // Grey-40
    600: '#979797',
    700: '#585858', // Grey-50
    800: '#656363',
    900: '#585858', // Grey-60
    A100: 'rgba(14, 14, 14, 0.5)', // corresponds to #0E0E0E with opacity 0.5, but it is darker
    A200: '#D8D8D8',
    A400: '#E0E0E0',
    A700: '#868484',
  },
  red: {
    50: '#FDEBE6',
    100: '#E5CDC6',
    400: '#F52020',
    500: '#E80030',
    600: '#EC2B53', // sentiments_negative
    700: '#B20A0A',
    800: '#FCEAEA',
    900: '#2C0101',
  },
  purple: {
    50: '#fcdfff',
    100: '#DCE1F2',
    300: '#7985A8',
    500: '#84258D', // accent_1_plum
    600: '#bd49b5',
    700: '#B6B5B4', // status anonymous
    800: '#7A3088', // status surveyed
    900: '#ca099f',
  },
  blue: {
    50: '#E5F6EE',
    100: '#D9EDF7',
    200: '#EDF5FF',
    300: '#00BBE0', // sentiments_unknown
    400: '#00BAAE', // accent_3_turquoise
    500: '#54bced',
    600: '#4b6bf1',
    700: '#5296e6',
    800: '#31708F',
    900: '#164c8c',
    A100: '#5495e612', // opacity background
    A200: '#00A7E9',
    A400: '#5495e6',
    A700: '#059F95',
  },
};

export const theme = {
  colors,
  fontFamily: 'Source Sans Pro, sans-serif',
  fontSizes: generateFontSizes(),
  fontWeights: {
    normal: 400,
    semi: 600,
    bold: 700,
  },
  texts: {
    heading1: {
      fontSize: '40px',
      fontSizeNumber: 40,
      fontWeight: 700,
    },
    heading2: {
      fontSize: '30px',
      fontSizeNumber: 30,
      fontWeight: 700,
    },
    heading3: {
      fontSize: '20px',
      fontSizeNumber: 20,
      fontWeight: 700,
    },
    heading4: {
      fontSize: '18px',
      fontSizeNumber: 18,
      fontWeight: 700,
    },
    plainText: {
      fontSize: '16px',
      fontSizeNumber: 16,
      fontWeight: 400,
    },
    secondaryText: {
      fontSize: '14px',
      fontSizeNumber: 14,
      fontWeight: 400,
    },
    placeholder: {
      fontSize: '16px',
      fontSizeNumber: 16,
      fontWeight: 400,
    },
    label: {
      fontSize: '20px',
      fontSizeNumber: 20,
      fontWeight: 700,
    },
    smallLabel: {
      fontSize: '12px',
      fontSizeNumber: 12,
      fontWeight: 600,
    },
  },
  container: {
    width: '75rem',
    smallWidth: '60rem',
  },
  formContainer: {
    width: '35rem',
  },
  proposals: {
    masthead: {
      height: '4.375rem',
    },
    stageBanner: {
      height: '3.5rem',
    },
    errorBanner: {
      height: '1.438rem',
    },
    questionsHeader: {
      height: '4.0625rem',
      backgroundColor: colors.white[600],
    },
    footer: {
      height: '5rem',
      backgroundColor: colors.white[600],
    },
  },
  screenPadding: '0 1.5rem',
  colorMappings: {
    CommonplaceBrand: colors.green[500],
    brand: colors.green[500],
    navColor: colors.green[500],
    accessibleBrand: colors.green['A700'],
    focusHighlight: colors.yellow[500],
    hoverHighlight: colors.green[50],
    selectedHighlight: colors.green[100],
    error: colors.red[700],
    warning: colors.orange[800],
    info: '#1da1f2',
    success: colors.green[500],
    white: colors.white[500],
    black: colors.black[500],
    plainText: colors.black[500],
    greyText: colors.grey[500],
    greyTextDarker: colors.grey[900],
    greyTextSelectedDark: '#515151',
    greyPlaceholderColor: colors.grey[700],
    secondaryText: colors.grey[500],
    accessibleSecondaryText: colors.grey[900],
    infoText: colors.blue[800],
    shadow: 'rgba(0,0,0,0.1)',
    darkerShadow: 'rgba(21, 20, 20, 0.46)',
    separators: colors.grey[300],
    editModePrimaryColor: colors.blue[700],
    editModeSecondaryColor: '#DDE2F1',
    editModeSelectedHighlight: lighten(0.36, colors.blue[700]),
    footerShadow: opacify(-0.56, '#CCCCCC'),
    warningDark: '#f4b840',
    warningLight: '#ffe7b2',
    lightRed: '#FFBDAD',
    red: '#DE350B',

    greyBorderLight: 'rgba(151, 151, 151, 0.25)',
    loginInputBorderColor: '#e5e5e5',
    loginBannerImageBackground: '#def4ea',
    appcuesBlue: '#D7D7FF',
    appcuesWhite: '#eee',
    greenText: '#0F5B38',
    lightGreyText: '#9e9e9e',

    // Social media
    facebook: '#1877f2',
    whatsapp: '#25d366',
    email: colors.grey[500],
    microsoftSSOGrey: '#8c8c8c',

    // Backgrounds
    lightGreenBackground: colors.green[50],
    pageBackgroundColor: colors.grey[50],
    emailPanelBackground: colors.purple[100],
    errorValidationBackground: colors.red[50],
    warningValidationBackground: colors.orange[100],
    infoValidationBackground: colors.purple[100],
    infoPanelBackground: colors.blue[100],
    editInfoPanelBackground: colors.blue[200],
    consentsOptionActiveBackground: colors.green[50],
    consentsOptionInactiveBackground: '#F1F1F1',
    specialCategoryQuestionsBackground: colors.green[50],
    contributionsTableHeaderModalNoOptionsBackground: '#EFEFEF',
    dashboardTableRowsHover: '#E7F7F0',
    dashboardNavBarYellow: '#FFD549',
    dashboardNavBarYellowHover: '#EBC64C',
    planAppTimelineBackground: 'rgba(234, 223, 199, 0.3);',
    planAppCommentSectionBackground: 'rgba(73, 54, 203, 0.05);',
    planAppTileBackground: 'rgba(150, 135, 233, 0.2)',
    matrixQuestionBackground: '#f3f5fA',
    voiceCaptureButtonBackground: '#0495FF',
    controlCenterNavbarBackground: '#FFC727',
    voiceCaptureTitle: '#202538',
    submitButtonBackground: '#DFF2FF',
    removeButtonBorder: '#F4C8CD',
    removeButtonColor: '#D0021B',
    controlCenterContentBackground: '#f6f6f6',
    controlCenterDisabledButtonBackground: '#ECEEEF',
    controlCenterNavBarText: '#455a64',
    controlCenterInfoRowBackground: '#FFC72733',
    voicePendingBackground: '#3784DD',
    commentReplyUserImageBackground: '#92aee3',
    chartBackground: '#FAFAFA',
    lightBackground: '#FFFAFA',
    systemMessageBackground: '#DEE3F1',

    // Button
    primaryButtonBackground: colors.green[500],
    editButtonBackground: colors.blue[700],
    secondaryButtonBackground: colors.grey[900],
    mapCancelButtonBackground: colors.red[500],
    teamMemberActionIcons: '#4C4C4C',
    teamMemberActionHover: '#1B9FFF',
    primaryButtonFocus: '#0B7B4D',
    lightGreyBackground: '#F4F4F4',
    editModeSelectedLayout: '#DCF0EF',
    editModeSelectedLayoutBorder: '#429893',
    primaryButtonHover: '#0B7142',
    lightButtonHover: 'rgba(0, 168, 99, 0.07)',
    lightGreenFocus: '#F2FAF6',

    // Disabled button
    disabledButton: colors.grey[800],
    disabledButtonBackground: colors.grey[300],
    disabledButtonLabel: colors.grey[900],
    disabledPlanAppButton: '#D6D6D6',

    // Form fields
    formFieldBorderLight: colors.grey[400],
    formFieldBorder: colors.grey[900],
    formFieldLabel: colors.black[500],
    placeholder: colors.grey[700],
    focusedInputBorder: '#7985A8',
    placeholderColor: '#757D8A',
    inputBorder: colors.white[300],
    errorBorder: '#FF5959',

    // Disabled form fields
    disabledFormFieldBorders: colors.grey[500],
    disabledFormFieldFill: colors.grey[300],
    disabledFormLabel: colors.grey[700],
    disabledFormInputValue: colors.grey[900],

    // Icons
    icon: colors.grey[500],
    disabledIcon: colors.grey[300],
    activeIcon: colors.grey[900],
    mapPinIcon: colors.purple[500],
    sentimentPositive: colors.green[500],
    sentimentMostlyPositive: colors.green[300],
    sentimentNeutral: colors.orange[500],
    sentimentMostlyNegative: colors.orange[300],
    sentimentNegative: colors.red[500],
    planningAppConsultation: '#F5B044',
    planningAppDecisionGranted: '#3BD08E',
    planningAppDecisionRefused: '#DC2475',
    planningAppAwaiting: colors.grey['A200'],
    planningAppAppeal: '#8860FA',
    planningAppWithdrawn: '#75C5EE',
    OnboardingProjectStatusactive: '#006DBC',
    OnboardingProjectStatusclosed: '#B20A0A',
    OnboardingProjectStatustesting: '#7B6B00',
    OnboardingProjectStatuscompleted: '#00791B',
    OnboardingProjectStatusarchived: '#767676',

    // hyperlinks
    hyperlink: colors.green[500],
    copyLink: colors.green[500],

    // Dashboard overview page cards
    dashboardOverviewVisitorsCard: '#1B365D',
    dashboardOverviewRespondentsCard: '#E56DB1',
    dashboardOverviewContributionsCard: '#00BFB2',
    dashboardOverviewNewSubscribersCard: '#FF802E',
    // alerts
    alertWarnBg: '#F8E9CD',
    alertWarnText: '#333',
    alertWarnBorder: '#F4B840',
    alertSuccessText: colors.green[900],
    alertSuccessBg: '#EAFCF5',
    alertSuccessBorder: colors.green[500],
    alertErrorText: colors.red[700],
    alertErrorBg: colors.red[800],
    alertErrorBorder: colors.red[400],

    emailPanelText: '#263238',
    surveySectionBackground: '#f9f9f9',
    surveyBadgePill: colors.purple[50],

    // Edit mode
    editModeBlue: '#5296E6',
    svgSelectedColor: '#6394E0',
    svgHoveredColor: '#45689d',
    trafficLights: {
      red: '#c82a1d',
    },

    //Planning apps
    planningAppsPurple: '#412dc9',

    descriptionTextColor: '#3F575C',
    editModeDarkGreyBackground: '#e2e5ec',
    editModePurple: colors.purple[500],
    // Tiles
    completedStageColor: '#00854E',
    activeStageColor: '#0079D1',
    // Public facing buttons
    greyButtonBorder: '#D8DAE5',
    greyButtonLabel: '#696F8C',
    greyButtonBorderHover: '#8F95B2',
    greyButtonLabelHover: '#474D66',
  },
};

export type ColorMapping = keyof typeof theme.colorMappings;
